body.generic {
    background: #fff;

    --headline: 'Montserrat', 'proxima-nova', 'Lato', system-ui, BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    --thick:'proxima-nova-black';
    --text: 'proxima-nova', system-ui, BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

    h1.page-h1 {
        color: var(--blue);
        font-size: 2.7rem;
        margin: 1rem 0 2rem;
        font-weight: 900;
        line-height: 1em;
        font-family: var(--thick);
        text-transform: uppercase;
        text-align: left;

        @include mobile {
            font-size: 1.8rem;
        }
    }

    section.intro {
        background: var(--grey);
        padding: 3rem 0 0rem;
        position: relative;

        &.blue {
            background: var(--blue);

            h1, p, h2, .eyebrow {
                color: var(--white);
            }
        }

        .c-modal & {
            padding: 5rem 0;
        }

        .text {
            max-width: 800px;
            position: relative;
            z-index: 10;
        }

        .eyebrow {
            font-weight: 600;
            text-transform: uppercase;
            color: #29ABE2;
        }

        h1 {
            color: var(--blue);
            font-weight: 900;
            font-size: 2rem;
            line-height: 1em;
            font-family: var(--headline);
            text-transform: uppercase;
            margin : 0 0 2rem;
            text-align: left;

            @include phablet-down {
                text-align: left;
            }

            @include mobile {
                font-size: 5rem;
            }

            a {
                color: var(--blue);

                &:hover {
                    color: var(--lightBlue);
                }
            }
        }

        h2 {
            font-size: 1.5rem;
            margin-bottom: 0;
            max-width: 740px;
            color: var(--blue);
        }

        p {
            color: var(--blue);
            font-size: 1.3rem;
            line-height: 1.5em;
            font-family: var(--text);

            &:last-of-type {
                margin-bottom: 0;
            }

            @include phablet-down {
                text-align: center;
            }
        }

        ul {
            li {
                color: var(--blue);
                font-size: 1.2rem;
                line-height: 1.6em;
                font-family: var(--text);
            }
        }

        .summary {
            display: flex;
            width: 100%;
            margin-top: 2rem;
            align-items:flex-end;

            @include phablet-down {
                flex-direction: column;
                text-align: left;
            }

            .desc {
                flex-grow: 1;

                strong {
                    font-size: 1.5rem;
                    display: block;
                    margin-bottom: 1rem;
                }

                p {
                    @include phablet-down {
                        text-align: left;
                    }
                }

            }

            .price {
                text-align: right;
                display: flex;
                flex-direction: column;
                align-items:flex-end;

                @include phablet-down {
                    align-items:flex-start;
                    margin-top: 2rem;
                }

                @include phablet-down {
                    text-align: left;
                }

                p {
                    display: flex;
                    font-size: 1rem;
                }

                em, strong {
                    padding: 0.25rem;
                    max-width: 6rem;
                    min-width: 6rem;
                    text-align: left;

                    @include phablet-down {
                        min-width: 5rem;
                        text-align: left;
                    }
                }

                span {
                    text-align: right;
                    margin-right: 0.5rem;
                    padding: 0.25rem 0.5rem;

                    @include phablet-down {
                        text-align: left;
                    }

                    &.total {
                        background:var(--blue);
                        color: #fff;
                        font-weight: bold;
                    }
                }
            }
        }

        h1 + .underbrow {
            margin-top: -22px;
        }

        .underbrow {
            font-weight: bold;
            color: var(--brightBlue);
            text-transform: uppercase;
            font-size: 22px !important;
        }
    }

    section.content {
        min-height:60vh;

        h3 + .underbrow {
            margin-top: -22px;
        }

        .underbrow {
            font-weight: bold;
            color: var(--brightBlue);
            text-transform: uppercase;
            font-size: 22px !important;
        }
    }

}