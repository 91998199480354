.c-card {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: #444;

    &:hover {
        .image {
            background-color: #0a6ca9;
        }
    }

    .image {
        height: 170px;
        background: #eee;
        transition: background-color 0.2s ease-in-out;
    }

    .text {
        margin-top: 10px;

        strong {
            display: block;
            margin-bottom: 5px;
            font-size: 18px;
        }

        p {
            font-size: 16px;
        }
    }

    &.committee {
        .text {
            margin-bottom: 30px;

            p {
                font-size: 16px;
                margin-bottom: 10px;
            }
        }

    }
    
}