div.input {
    box-shadow: none !important;
    border-width: 0px;
    padding: 0;
}

div.multitextrow {
    div.field {
        div.heading {
            display:none;
        }
    }
}

.label {
    font-size: 1.3rem;
    
    &:not(:last-child) {
        margin-bottom: .25em;
    }  
}

.field-body {
    flex-grow: 3;

    &.large {
        flex-grow: 1.5;
    }
}

input.hidden {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 1;
}

.field {
    input, select {
        scroll-margin-top: 50px;
    }

    position: relative;

    .select {
        width: 100%;

        select {
            width: 100%;
        }
    }

    .checkbox {
        padding: 0.5rem 0;

        input {
            margin-right: 0.25em;
        }
    }

    .radios {
        min-height: 40px;
        align-items: center;
        display: flex;

        .radio {
            margin-right: 0.5em;
            input {
                margin-right: 0.25em;
            }
        }
    }
}

.label.twoLines {
    transform: translateY(-12px);
}

.is-horizontal {
    .field-label {
        padding-top: 8px;
    }
}

.v-select {
    background-color: #ffffff;
    border-color: #dbdbdb;
    border-radius: 4px;

    .vs__dropdown-toggle {
        padding: 7px 12px;
    }
}

.autocomplete-input[role=combobox] {
    padding: 7px 12px 7px 48px;
    background-color: #ffffff;
    border-color: #dbdbdb;;
    border-radius: 4px;
}

.section-title {
    padding: 1rem;
    background: #162a50;
    color: #fff;
    margin-bottom: 0 !important;
}

.section-content {
  background: #e3ebee !important;
  padding: 2rem;
}

section.box {
    padding: 0 !important;
    margin-bottom: 3rem !important;
}

.is-max-desktop {
    width: 100%;
}

.checkbox.confidential {
    color: #999;
    cursor: not-allowed;
}

.tooltip {
    border-bottom: 1px dotted #000000; color: #000000; outline: none;
    cursor: help; text-decoration: none;
    position: relative;
}

.tooltip span {
    margin-left: -999em;
    position: absolute;
}

.tooltip:hover span {
    border-radius: 5px 5px; -moz-border-radius: 5px; -webkit-border-radius: 5px; 
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1); -webkit-box-shadow: 5px 5px rgba(0, 0, 0, 0.1); -moz-box-shadow: 5px 5px rgba(0, 0, 0, 0.1);
    font-family: Calibri, Tahoma, Geneva, sans-serif;
    position: absolute; left: -10em; top: 2em; z-index: 99;
    margin-left: 0; width: 200px;
}

.tooltip:hover img {
    border: 0; margin: -10px 0 0 -55px;
    float: left; position: absolute;
}

.tooltip:hover em {
    font-family: Candara, Tahoma, Geneva, sans-serif; font-size: 1.2em; font-weight: bold;
    display: block; padding: 0.2em 0 0.6em 0;
}

.classic { padding: 0.8em 1em; }

.classic {background: #FFFFAA; border: 1px solid #FFAD33; }
