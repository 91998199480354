body.base-logged-out {
    background-color: var(--blue) !important;

    div.logo {
        padding-bottom: 1.5rem;
        max-width: calc( 100vw - 6rem );
        margin: 0 auto;
        text-align: center;
        
        img {
            margin: 0 auto;
        }
    }

    .hero-body {
        padding: 1rem 0;
        justify-content: center;

        .content-wrapper {
            display: flex;
            justify-content: center;
            max-width: calc( 100vw - 2rem );
            margin: 0 auto;
        }

        .column {
            padding: 0;
        }
    }

    label.h1,
    h1 {
        font-family: var(--thick);
        text-align: center;
        color: var(--lightBlue);
        font-weight: 400;
        font-size: 24px;
        line-height: 1.4em;
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 1rem;

        &.page-h1 {
            color: var(--blue);
            font-size: 2.7rem;
            margin: 1rem 0 2rem;
            font-weight: 900;
            line-height: 1em;
            font-family: var(--thick);
            text-transform: uppercase;
            text-align: left;

            @include mobile {
                font-size: 1.8rem;
            }
        }
    }

    h2 {
        font-family: var(--thick);
        text-align: center;
        font-weight: 400;
        font-size: 24px;
        line-height: 1.4em;
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 1rem;
    }

    a {
        &:hover {
            h2.has-text-warning {
                color: #f14668 !important;
            }
        }
    }

    label.h1,
    label h1 {
        text-align: left;
        margin-bottom: 0;

        @include mobile {
            font-size: 20px;
        }
    }

    .notification.is-danger {
        border-radius: 5px;
    }

    ul.errors.has-text-danger {
        background: #f14668;
        color: #fff;
        padding: 10px;
        margin-bottom: 15px;
        border-radius: 5px;

        li {
            color: #fff !important;
        }
    }

    .content {
        p {
            font-size: 1.3rem;
        }
    }

    input[type=text],
    input[type=email], 
    input[type=password] {
        background: #efefef;
    }

    select {
        padding: 0.75em 0.5em;
        background: #efefef;
        width: 100%;
        font-size: 16px;
    }
}