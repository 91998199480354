html, body {
    padding: 0;
    margin : 0;
    font-weight: 400;
    font-family: var(--text);

    .noscroll {
        overflow: hidden !important;
    }    
}

html {
    height: -webkit-fill-available;
}

body {
    min-height : 100vh;
    min-height : -webkit-fill-available;
	position   : relative;
    background : var(--white);
    
    @include tablet-down {
        overflow-x: hidden;
    }

    &.has-mobile-menu {
        overflow  : hidden;
        height    : 100vh;
        max-height: 100vh;
        min-height: 100vh;
    }
}

::selection {
	background-color: var(--paleYellow); /* WebKit/Blink Browsers */
}

::-moz-selection {
	background-color: var(--paleYellow); /* Gecko Browsers */
}