/**
 * Typography rules
 * ==============================================================
 * @font-face embeds go in `templates/_partials/sites-fonts.twig`
**/

h1, h2, h3, h4 {
    font-weight: normal;
}

h1 {
    font-size: 5rem;

    &.page-h1 {
        color: var(--blue);
        font-size: 3.6rem;
        max-width: 960px;
        margin: 0 0 2rem;
        font-weight: 900;
        line-height: 1em;
        font-family: var(--headline);
        text-transform: uppercase;


        .eyebrow {
            font-weight: 900;
            line-height: 1em;
            font-family: var(--headline);
            text-transform: uppercase;
            color: var(--lightBlue);
            font-size: 2rem;
            margin-bottom: 0.5rem;
            display: block;
        }
    }
}

.c-block {
    label.font-headline,
    h2.font-headline {
        font-family: var(--headline) !important;
        font-weight: 900;
        font-size: 24px;
        text-transform: uppercase;
        color: var(--lightBlue);
    }
}


.card-header h2 {
    font-family: var(--headline) !important;
    font-weight: 900;
    font-size: 24px;
    text-transform: uppercase;
}


h2 {
    font-size: 4rem;

    &.block-headline {
        font-family: var(--script);
        font-size: 8rem;
        color: var(--dark);
    }

    &.title.is-3 {
        font-family: var(--headline) !important;
        font-weight: 900;
        font-size: 24px;
        text-transform: uppercase;        
        color: var(--lightBlue);
    }
}

%h2 {
    color: #162A50;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    font-family: var(--text);
    text-transform: uppercase;
    margin-bottom: 5rem;
}

h3 {
    font-size: 3.5rem;

    &.block-headline {
        text-align: center;
        font-family: var(--script);
        font-size: 6rem;
        color: var(--dark);
        margin: 0 auto 2rem;
    }
}

main.page {
    p, li {
        font-size: 1.8rem;
        line-height: 1.5em;
    
        &.lead {
            font-size: 2.2rem;
        }

        @include mobile {
            font-size: 2rem;

            &.lead {
                font-size: 2.4rem;
            }
        }
    }
}

span.na {
    color: #777;
    font-size: 85%;
}