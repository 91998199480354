header.base-header {
    background: var(--blue);
    padding: 0rem 0rem;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 100;

    @include phablet-down {
        padding: 0rem 0rem;
    }

    img {
        max-height: 5.5rem;
        padding-left: 0rem;
        display: block;
        margin: 0;
        line-height: 1em;
        transition: max-height 0.3s ease;
        
        body.scrolled & {
            max-height: 59px;
            // transition: 0s;
        }
    }

    .navbar {
        background: transparent;
    }

    .navbar-end {
        align-items: center;
    }

    .navbar-brand {
        align-items: center;
        padding: 10px 0;
    }

    .navbar-burger {
        color: var(--white);

        span {
            width: 24px;
            height: 2px;
            left: calc(50% - 12px);

            &:nth-child(1) {
                top: calc(50% - 10px);
            }

            &:nth-child(2) {
                top: calc(50% - 2px);
            }

            &:nth-child(3) {
                top: calc(50% + 6px);
            }
        }
    }

    .navbar-item {
        max-height: 40px;
        color: var(--white);

        &:active,
        &:focus,
        &:focus-within,
        &:hover {
            color: #3273dc;

            a.navbar-item,
            a.navbar-link {
                color: var(--blue);

                &:hover {
                    color: #3273dc;
                }
            }
        }

        a.navbar-link {
            color: #fff;

            &:hover {
                color: #3273dc;
            }
        }
    }
}

.subnav {
    top: calc( 5.5rem + 20px ) !important;
    transition: top 0.3s ease;

    body.scrolled & {
        top: 79px !important;

        // position: sticky !important;
    }
}