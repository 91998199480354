div#main {
    scroll-margin-top: 130px;
}

.page-research-insight,
.page-home {
    .block-wrapper-reportList {
        background: var(--blue);
        margin-top: 130px;
        position: relative;
    
        .c-block {
            padding: 90px 0 60px;
    
   
            h2, p, li, .col {
                color: #fff;

                a {
                    color: #fff !important;
                }
            }
    
            .row:nth-child(even) {
                background-color: var(--darkBlue) !important;
            }

            h2.headline {
                position: relative;
                padding-top: 55px;
                margin-top: -107px;
                color: #fff;

                span.eyebrow {
                    position: absolute;
                    top: 0;
                    transform: translateX(-17px);
                    background: var(--yellow);
                    color: var(--blue);
                    display: inline-block;
                    margin: 0;
                    padding: 6px 9px 4px;
                    line-height: 1em;
                    font-family: var(--thick);
                    font-size: 22px;

                    @include tablet-down {
                        transform: translateX(-5px);
                    }
                }
            }
        }
    }
}

.block-wrapper-greyBg {
    background: var(--grey);
    margin-top: 130px;
    position: relative;

    .c-block {
        padding: 70px 0 40px;

        @include desktop-up {
            padding: 90px 0 60px;
        }

        @include mobile {
            padding: 40px 0 30px;
        }

        h2, p, li {
            color: #000;
        }

        h2.headline {
            position: absolute;
            top: -39px;
            left: 50%;
            transform: translateX(-50%);
            margin : 0;
            border: 3px solid var(--grey);
            background: #fff;
            padding: 10px 15px;
            display: inline-block;

            @include legacy-up {
                max-width: 720px;
            }

            @include tablet-down {
                font-size: 38px;
                max-width: 90%;
                white-space: nowrap;
            }

            @include mobile {
                font-size: 24px;
            }

            @include mobile {
                top: -28px;
            }
        }
    }

    .block-threeColumns {
        padding-top: 80px;
    
        @include desktop-up {
            padding-top: 120px;
        }

        h2.headline {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &:last-child {
        margin-bottom: -130px;
    }
}


.report-content {
    > div:first-of-type {
        &.block-wrapper {
            h2.headline {
                margin-top: 0px;
            }
        }
    }

    .block-wrapper-callout,
    .block-wrapper-table {
        margin-top: 80px;
        
        @include tablet-down {
            margin-top: 50px;
        }

    }

    .block-wrapper {
        margin-bottom: 30px;

        h2.headline {
            margin-top: 80px;
        }
    }

    .block-textImage {
        .image {
            @include tablet {
                max-width: 350px;
            }

            @include phablet {
                max-width: 270px;
            }

            @include legacy {
                max-width: 400px;
            }

            @include desktop {
                margin-right: -140px;
                max-width: 500px;
            }
    
            @include widescreen {
                margin-right: -220px;
                max-width: 500px;
            }
        }
    }
}

.block-wrapper {
    margin-bottom: 80px;
}

.c-block {
    margin : 0 auto;
    text-align: left;

    p {
        font-size: 22px;
        line-height: 1.6em;
        margin-top: 0;
        margin-bottom: 35px;

        @include tablet {
            font-size: 18px;
            margin-bottom: 26px;
        }

        @include phablet {
            font-size: 16px;
            margin-bottom: 26px;
        }

        @include mobile {
            font-size: 14px;
            margin-bottom: 22px;
        }

        &.small {
            font-size: 16px;
            line-height: 1.3em;
        }

        &.lede {
            font-size: 28px;
            line-height: 1.45em;
            letter-spacing: -0.03em;

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            @include tablet-down {
                font-size: 22px;
            }

            @include tiny {
                font-size: 19px;
            }
        }
    }

    li {
        font-size: 22px;
        line-height: 1.4em;
        margin-top: 0;
        margin-bottom: 5px;

        @include tablet {
            font-size: 18px;
        }

        @include phablet {
            font-size: 16px;
            margin-bottom: 26px;
        }

        @include mobile {
            font-size: 14px;
            margin-bottom: 22px;
        }
    }

    p, li {
        a {
            color: #0a6ca9;
            font-weight: bold;

            &:hover {
                color: #142a51;
            }
        }

        mark {
            background-color: var(--yellow);
        }
    }

    p + h2, ul + h2, ol + h2 {
        margin-top: 50px;
    }

    ul, ol {
        margin-bottom: 35px;

        @include mobile {
            padding-left: 1em;
        }

        li {
            margin-bottom: 15px;
        }
    }

    ul + p, ol + p {
        margin-top: 25px;
    }

    max-width: 940px;

    a.fake-link {
        text-decoration: none;
        color: #222;

        html:not(.user-is-tabbing) & {
            &:active, &:focus {
                outline: none;
            }
        }

    }

    h2.block {
        display: inline-block;
        max-width: 280px;
        line-height: 1.6em;
        
        span {
            color: #fff;
            text-transform: uppercase;
            padding: 3px;
            background: #023858;
        }
    }

    p + h2, ol + h2, ul + h2 {
        margin-top: 60px;

        @include phablet-down {
            margin-top: 30px;
        }
    }

    p + h3, ol + h3, ul + h3 {
        margin-top: 60px;

        @include phablet-down {
            margin-top: 30px;
        }
    }

    h2 {
        font-family: bebas-neue;
        font-weight: normal;
        text-transform: uppercase;
        font-size: 44px;
        color: var(--blue);
        margin-top: 10px;
        margin-bottom: 26px;
        max-width: 600px;
        scroll-margin-top: 150px;

        @include mobile {
            font-size: 34px;
            margin-bottom: 16px;
        }

        &.center {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }

        strong,
        .eyebrow {
            font-family: var(--thick);
            color: var(--brightBlue);
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 1.8px;
            display: block;

            @include mobile {
                font-size: 17px;
                letter-spacing: 1px;
            }
        }
    }

    h2.headline.cvca-intelligence {
        background-image: url(/assets/images/logos/cvca-intelligence.png);
        background-repeat: no-repeat;
        background-size:contain;
        height: 90px;
        overflow: hidden;
        text-indent: -9999px;
    }


    h3,
    h3.title {
        font-family: var(--thick);
        letter-spacing: 0.72px;
        color: var(--blue);
        font-size: 24px;
        opacity: 1;
        text-transform: uppercase;
        margin-bottom: 10px;
        max-width: 600px;
        scroll-margin-top: 150px;

        &.center {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }

        @include mobile {
            letter-spacing: 0px;
            font-size: 18px;
            line-height: 1.1em;
        }
    }

    #ajax-content & {
        padding: 0 20px;

        @include mobile {
            padding: 0;
        }
    }
    
}

.c-card {
    position: relative;

    .image {
        overflow: hidden;
        img {
            min-height: 100%;
        }
    }
}


.block-text {
     
    ul:last-child,
    ol:last-child,
    p:last-child {
        margin-bottom: 0;
    }

    figure {
        //border: 4px solid #3777bc;
        margin: 40px auto;
        text-align: left;

        @include tablet-down {
            margin: 20px auto;
        }

        img {
            display: inline-block;
            margin: 0 auto;
            padding: 0;
            line-height: 1em;
        }
    }
}

div.block-wrapper-textImage[class*="Alt"]
 + div.block-wrapper:not([class*="Alt"]) {
    margin-top: 120px;
}


div.block-wrapper-textImage[class*="Alt"]
 + div.block-wrapper-greyBg {
    margin-top: 180px !important;
}


.block-textImage {

    &.rightStd,
    &.rightEql {
        .wrapper {
            div.image {
                img {
                    transition: all 0.5s ease-out 0.5s;
                    opacity: 0;
                    transform: translateY(50px);
                }
            }
        }

        &.active {
            .wrapper {
                div.image {
                    img {
                        opacity: 1;
                        transform: translateY(0px);
                    }
                }
            }
        }
    }


    &.rightStd {
        .wrapper {
            @include mobile {
                flex-direction: column-reverse;

            }
        }

        .image {
            @include mobile {
                min-width: 100%;
                max-width: 100%;
                margin-bottom: 20px;
            }

            @include tablet {
                max-width: 350px;
            }

            @include phablet {
                max-width: 270px;
            }

            @include legacy {
                max-width: 400px;
            }

            @include desktop {
                margin-right: -140px;
                max-width: 500px;
            }
    
            @include widescreen {
                margin-right: -220px;
                max-width: 500px;
            }
        }
    }

    &.leftAlt,
    &.leftAltBlue,
    &.rightAlt,
    &.rightAltBlue {

        h2.headline {
            //transform: translateX(-18px) rotate(-1deg);
            transform: translateX(-18px);
            margin: 20px 0 35px;
            background: var(--blue);
            color: #fff;
            padding: 10px 18px;
            display: inline-block;
        }

        .wrapper {
            @include mobile {
                flex-direction: column-reverse;
            }

            h3 {
                margin-top: 0;
                max-width: 540px;
            }

            .button-wrapper {
                margin-top: -10px;
                padding-bottom: 0;
            }

            div.image {
                max-width: 180px;
                padding-top: 25px;

                img {
                    transition: all 0.5s ease-out 0.5s;
                    opacity: 0;
                }

                @include tablet {
                    max-width: 140px;
                }

                @include mobile {
                    margin-right: auto;
                    margin-left: auto;
                    margin-bottom: 20px;
                    max-width: 140px;
                }
            }
        }

        &.active {
            .wrapper {
                div.image {
                    img {
                        opacity: 1;
                        transform: translateX(0px);
                    }
                }
            }
        }
    }


    &.leftAltBlue,
    &.rightAltBlue {
        .wrapper {
            background:var(--opaqueBlue);
            padding: 30px;
            margin: -30px;

            @include tablet {
                margin-left: -10px;
                margin-right: -10px;
            }

            @include phablet-down {
                padding: 30px 15px;
                margin-left: -10px;
                margin-right: -10px;
            }

            @include legacy {
                margin-right: -18px;
                margin-left: -18px;
            }

            div.image {
                padding-top: 0px;
            }
        }
    }

    &.rightAlt,
    &.rightAltBlue {
        div.image img {
            transform: translateX(50px);
        }
    }

    &.leftAlt,
    &.leftAltBlue {
        div.image img {
            transform: translateX(-50px);
        }

        .wrapper {
            flex-direction: row-reverse;

            @include mobile {
                flex-direction: column-reverse;
            }

            .text {
                margin-right: 0;
                margin-left: 40px;

                @include tablet-down {
                    margin-left: 20px;
                }

                @include mobile {
                    margin-left: 0;
                }
            }
        }
    }


    &.rightEql {
        .wrapper {
            @include mobile {
                flex-direction: column-reverse;
            }

            .text {
                max-width: 50%;

                @include mobile {
                    max-width: 100%;
                }
            }

            .image {
                max-width: 50%;

                @include mobile {
                    max-width: 100%;
                }
            }
        }
    }

    &.greyBg {
        div.wrapper {
            @include mobile {
                flex-direction: column-reverse;
            }
        }

        div.text {

        }

        div.image {
            img {
                transition: all 0.5s ease-out 0.5s;
                opacity: 0;
                transform: translateX(50px);
            }

            @include tablet {
                max-width: 140px;
            }

            @include mobile {
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 20px;
                max-width: 140px;
            }
        }

        &.active {
            .wrapper {
                div.image {
                    img {
                        opacity: 1;
                        transform: translateX(0px);
                    }
                }
            }
        }
    }
    
    .wrapper {
        display : flex;

        @include mobile {
            flex-direction: column-reverse;
        }

        .image {
            @include mobile {
                margin-bottom: 15px;
            }
        }
    }

    h2.headline {
        margin-top: 0;
    }

    .text {
        flex: 1;
        margin-right: 40px;

        @include tablet-down {
            margin-right: 20px;
        }

        @include mobile {
            margin-right: 10px;
        }

        @include mobile {
            margin-right: 0;
        }

        h3 {

            &:first-child {
                margin-top: 0;

                @include mobile {
                    text-align: center;
                }
            }
            
        }

        ul:last-child,
        ol:last-child,
        p:last-child {
            margin-bottom: 0;
        }

        figure {
            margin: 0;
            max-width: 640px;
            width: 100%;
        }
    }

    // remove after before launch
    .image.default {
        height: 175px;
        width: 200px;
        background: #eee;

        @include tablet-down {
            height: 140px;
            width: 140px;
        }
    }

    &.greyBg {
        ul {
            margin: 0 0 38px 0;
            padding: 0;
            list-style: none;

            li {
                margin: 0 0 0 4px;
                padding: 0 0 0 28px;
                background: url('/assets/static/bullet.png') no-repeat left 4px;
                background-size: 20px 20px;
                font-size: 20px;
                line-height: 1.4em;
                margin-bottom: 20px;

                @include mobile {
                    font-size: 15px;
                }

                p { 
                    font-size: 20px;
                    line-height: 1.4em;

                    @include mobile {
                        font-size: 15px;
                        line-height: 1.3em;
                    }
                }

                a {
                    font-size: 20px;
                    text-transform: uppercase;
                    text-decoration: none;

                    @include mobile {
                        font-size: 15px;
                    }
                }


            }
        }
    }
}

div.page-content.page-home {
    > div:first-of-type {

        &.block-wrapper-callout {
            margin-top: -125px;
            position: relative;
            z-index: 100;
}

        .block-callout {
            animation-delay: 2.5s;            

            .wrapper {
                p + ul {
                    margin-top: 16px;

                    li {
                        margin-bottom: 5px;
                        font-size: 17px;
                    }
                }
            }

            body.scrolled & {
                animation-delay: 0.5s;
            }
        }
    }
}

div.page-content.page-home {
    > div:first-of-type {

        &.block-wrapper-textVideo {
            margin-top: -130px;
            transform: translateY(130px);
            margin-bottom: -60px;
            position:  relative;
            z-index: 10;

            transform: translateY(100px);
            opacity: 0;
            animation: slideUp;
            animation-fill-mode: forwards;
            animation-duration: 0.6s;
            animation-delay: 2.5s;

            body.scrolled & {
                animation-delay: 0.5s;
            }

            .block-textVideo {
                h2 {
                    background: #fff;
                    display: inline-block;
                    padding-left: 20px;
                    padding-top: 15px;
                    padding-right: 25px;
                    border-top: 5px solid var(--brightBlue);
                    font-family: var(--thick);
                    letter-spacing: 0.72px;
                    color: var(--blue);
                    font-size: 24px;
                    opacity: 1;
                    text-transform: uppercase;
                    margin-bottom: 0px;
                    padding-bottom: 20px;
                    // max-width: max( calc(100vw - 40px), 600px );
                    width: calc( 100% - 45px );

                    @include mobile {
                        font-size: 20px;
                    }
                }

                h2 + .wrapper {
                    background-color: #fff;
                    padding-left: 20px;
                }
            }  

            .video-wrapper {
                transition : none !important;
            }

            .video-float {       
                margin-top: 0;
                padding-right: 20px;
                
                @include tablet-up {
                    padding-top: 20px;
                }

                @include desktop-up {
                    transform: translateX(10px);
                    position: relative;
                    z-index: 21;
                }
            }
        }
    }
}


div.page-content:not(.page-home):not(.report-content) {
    > div:first-of-type {
        overflow: visible;

        .block-callout {
            transform: translateY(100px);
            opacity: 0;
            animation: slideUp;
            animation-fill-mode: forwards;
            animation-duration: 0.6s;
            animation-delay: 0.4s;
            padding-top: 0;
            
            .wrapper {
                background: var(--brightBlue);

                p {
                    color: var(--blue);
                }
            }
        }

        .block-textVideo {
            .video-wrapper {
                transform: translateX(0px) translateY(100px);
                opacity: 0;
                transition : none !important;
                animation: slideUp;
                animation-fill-mode: forwards;
                animation-duration: 0.6s;
                animation-delay: 0.4s;
            }

            .video-float {       
                @include desktop-up {
                    margin-top: -140px;
                    position: relative;
                    z-index: 201;
                }

                .video-wrapper {
                    border-color: #fff;
                }
            }
        }
    }
}


.block-wrapper-textVideo {
    max-width: 100%;
    overflow: hidden;
}

.block-textVideo {

    &.active {
        .video-wrapper {
            transform: translateX(0px) translateY(0);
            opacity: 1;
            transition : all 0.6s cubic-bezier(0.25, 1, 0.5, 1) 0.4s;
        }
    }

    .text {
        position: relative;

        figure {
            margin-bottom: 40px;

            img {
                max-width: 300px;
            }
        }
    }

    .video-float {
        float: right;
        margin: 8px 0 80px 40px;

        @include tablet {
            margin: 3px 0 50px 20px;
        }

        @include phablet-down {
            float: none;
            margin: 10px 0 30px 0px;
        }

        @include desktop {
            margin-right: -140px;
        }

        @include widescreen {
            margin-right: -220px;
        }
    }

    .video-wrapper {
        position: relative;
        width: 448px;
        height: 252px;
        padding-bottom: 0 !important;
        margin: 0 !important;
        border: 4px solid var(--azure);

        @include legacy-up {
            transform: translateX(70px) translateY(40px);
            opacity: 0;
        }

        @include desktop-up {
            width: 560px;
            height: 315px;
        }

        @include tablet {
            width: 360px;
            height: 202px;
        }

        @include phablet-down {
            width: calc( 100% - 8px );
            height: auto;
        }

        .video {
            @include phablet-down {
                position: relative;
                padding-bottom: 56.25% !important;
                height: 0;
            }

            iframe {
                @include phablet-down {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        iframe {
            width: 448px;
            height: 252px;

            @include desktop-up {
                width: 560px;
                height: 315px;
            }

            @include tablet {
                width: 360px;
                height: 202px;
            }
        }
    }
}


.block-headshots {
    .wrapper {
        display : flex;
        //justify-content: space-between;
        flex-wrap: wrap;

        .person {
            min-width: calc(25% - 20px);
            max-width: calc(25% - 20px);
            margin-bottom: 40px;
            margin-right: 20px;

            @include mobile {
                min-width: calc(50% - 20px);
                max-width: calc(50% - 20px);
            }

            text-align: center;
        }

        .text {
            padding-top: 10px;
            strong {
                margin-bottom: 5px;
                font-family: var(--thick);
                color: var(--blue);
                font-weight: normal;
                font-size: 18px;
                line-height: 20px;
                letter-spacing: 1.3px;
                display: block;
                text-transform: uppercase;
            }

            .position {
                b {
                    font-weight: normal; 
                    font-size: 18px;
                    display: inline-block;
                    margin-left: 4px;
                    color: #666;
                }
            }

            .boardPosition {
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: var(--blue);
                color: #fff;
                padding: 2px 4px;
                margin-bottom: 8px;

                @include tablet-down {
                    line-height: 0.9em;
                }
            }
        }
    }
}


.block-threeColumns {

    &.active {
        .wrapper {
            .col {
                transform: translateY(0);
                opacity: 1;
                transition : all 0.6s cubic-bezier(0.25, 1, 0.5, 1);

                &:nth-of-type(2) {
                    transition-delay : 0.25s;
                }

                &:nth-of-type(3) {
                    transition-delay : 0.5s;
                }
            }
        }
    }

    .button-wrapper {
        padding-bottom: 0;
    }


    .wrapper {
        display : flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include desktop-up {
            margin-right: -140px;
            margin-left: -140px;
        }

        @include mobile {
            flex-direction: column;
        }

        .col {
            width : calc( 33.33% - 20px );
            margin-bottom: 0px;

            @include legacy-up {
                transform: translateY(100px);
                opacity: 0;
            }

            @include tablet-down {
                margin-bottom: 40px;
            }

            @include mobile {
                width: 100%;
                max-width: 380px;
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 55px;
            }

            display: flex;
            flex-direction: column;
            align-items: center;

            &.hasButton {
                padding-bottom: 50px;
                position: relative;

                .button-wrapper {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    left: 0;
                    text-align: center;
                }
            }
        }

        h3.title {
            text-align: center;
            font-family: var(--thick);
            letter-spacing: 0.72px;
            color: var(--brightBlue);
            font-size: 24px;
            opacity: 1;
            text-transform: uppercase;
            margin: 0 auto;
            height: 60px;
            display: flex;
            align-items: center;

            @include tablet-down {
                letter-spacing: 0px;
                color: var(--brightBlue);
                font-size: 21px;
                height: 52px;
            }
        }

        .image {
            margin: 18px 0;
            text-align : center;

            img {
                max-width: 150px;
                margin: 0 auto;
            }
        }

        .text {
            p {
                font-size: 15px;
                text-align: center;
                max-width: 360px;
            }           
        }
    }
}


.video-tiles {
    padding-top: 30px;

    .wrapper {
        .tile {
            display : flex;       
            justify-content: space-between;
            width : 100%;
            margin-bottom: 70px;
            position: relative;

            @include mobile {
                max-width: 380px;
                margin-bottom: 30px;
                margin-left: auto;
                margin-right: auto;
                flex-direction: column-reverse;
            }

            &:hover {
                .image {
                    img {
                        transform: scale(1.05);
                    }
                }
            }

            h3 {
                margin-top: 0;
                span.eyebrow {
                    display: block;
                    font-size: 13px;
                    color: var(--brightBlue);
                    margin-top: -14px;
                }
            }

            .sponsor {
                text-transform: uppercase;
                margin-bottom: 10px;
            }
            
            .image {
                min-width : calc( 33.33% - 20px );
                max-width : calc( 33.33% - 20px );
                padding-top: 2px;
                margin-left: 20px;
                overflow: hidden;

                img {
                    transition: transform 0.3s ease-in-out;
                    transform-origin: 50% 50%;
                }

                @include mobile {
                    margin-bottom: 20px;
                    min-width : 100%;
                    max-width : 100%;
                    margin-left: 0px;
                }
            }

            .text {

                p {
                    font-size: 16px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                a.watch-video {
                    color: var(--blue);
                    display: inline-block;
                    white-space:nowrap;
                    margin-left: 10px;
                    font-weight: bold;
                    transition: color 0.3s ease-in-out;

                    &.solo {
                        margin-left: 0;
                    }

                    &:hover {
                        color: var(--brightBlue);
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 4;
                    }


                }
            }
        }
    }
}


.block-tiles {
    .wrapper {
        display : flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include mobile {
            flex-direction: column;
        }

        .tile {
            width : calc( 33.33% - 20px );
            margin-bottom: 40px;

            @include phablet {
                width : 48%;
                max-width: 380px;
                margin-bottom: 30px;
            }

            @include mobile {
                width : 100%;
                max-width: 380px;
                margin-bottom: 30px;
                margin-left: auto;
                margin-right: auto;
            }

            &:hover {
                .text {
                    a.headline {
                        color: var(--brightBlue);
                        transition: color 0.3s ease-in-out;
                    }
                }

            }

            .text {
                a.headline {
                    color: var(--blue);
                    display: block;
                    font-family: var(--text);
                    font-size: 17px;
                    font-weight: 700;
                    text-transform: uppercase;
                    line-height: 1.25em;
                    text-decoration: none;
                    display: inline-block;
                    margin-bottom: 5px;

                    @include tablet {
                        font-family: var(--text);
                        font-weight: bold;
                        font-size: 16px;
                    }

                    @include phablet {
                        font-family: var(--thick);
                        font-size: 16px;
                    }
                }
            }
        }
    }
}


.block-featuredVideos,
.block-externalArticleList {

    &.tiles {
        p {
            text-align: center;
        }
    }

    p + .wrapper {
        margin-top: 50px;
    }
    
    .button-wrapper {
        &.tiles {
            justify-content: center;
            display: flex;
        }
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        &.tiles {
            justify-content: space-between;
            flex-wrap: wrap;
            flex-direction: row;
    
            @include desktop-up {
                margin-right: -140px;
                margin-left: -140px;
            }

            @include phablet-down {
                flex-direction: column;
            }

            .tile {
                width : calc( 33.33% - 20px );
                margin-bottom: 40px;
                background: #fff;

                @include tablet {
                    width : calc( 33.33% - 10px );
                }

                @include phablet-down {
                    width : calc( 100% );
                    max-width: 380px;
                    margin-right: auto;
                    margin-left: auto;
                }

                .c-card {
                    min-height: 100%;
                    overflow: hidden;

                    .image {
                        height: 200px;

                        @include tablet {
                            height: 150px;
                        }

                        @include phablet {
                            height: 200px !important;
                        }

                        @include desktop-up {
                            height: 245px;
                        }

                        img {
                            object-position: 50% 50%;
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                            max-width: inherit;
                            min-height: inherit;
                        }
                    }

                    &:hover {
                        .text {
                            position: relative;
                            z-index: 2;
                            
                            @include desktop-up {
                                transform: translateY(-65px);
                            }

                            @include legacy {
                                transform: translateY(-85px);
                            }

                            &::before {
                                @include desktop-up {
                                    min-height: calc( 100% + 65px );
                                    transform: translateY(65px);
                                }
    
                                @include legacy {
                                    min-height: calc( 100% + 85px );
                                    transform: translateY(85px);
                                }
                            }

                            a.headline {
                                color: var(--azure);
                                transition: color 0.3s ease-in-out;
                            }

                            p {
                                @include legacy-up {
                                    max-height: 200px;
                                    opacity: 1;
                                    margin-top: 8px;
                                    transition: opacity 0.3s linear 0.2s;
                                }
                            }
                        }
                    }

                    .text {
                        flex: 1;
                        margin-top: 0;
                        padding: 15px 20px 15px;
                        background: #fff;
                        transition: transform 0.3s ease-in-out;
                        position: relative;

                        &::before {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            min-height: 100%;
                            width: 100%;
                            background: #fff;
                            display: block;
                            z-index: 0;
                            transition: all 0.3s ease-in-out;
                        }

                        @include tablet {
                            padding: 12px 15px;
                        }

                        @include legacy {
                            padding: 12px 15px;
                        }

                        @include mobile {
                            padding: 12px 12px;
                        }

                        a.headline {
                            color: var(--blue);
                            display: block;
                            font-family: var(--thick);
                            font-size: 18px;
                            font-weight: normal;
                            text-transform: uppercase;
                            line-height: 1.25em;
                            text-decoration: none;
                            position: relative;
                            z-index : 2;

                            @include legacy {
                                font-size: 17px;
                            }

                            @include tablet {
                                font-family: var(--text);
                                font-weight: bold;
                                font-size: 16px;
                            }

                            @include phablet {
                                font-family: var(--thick);
                                font-size: 16px;
                            }

                            sup {
                                font-size: 10px;
                                vertical-align: 4px;
                                font-family: var(--text);
                                font-weight: bold;
                            }
                        }

                        p {
                            text-align: left;
                            font-size: 15px;
                            line-height: 1.3em;
                            position: absolute;
                            margin: 0;
                            padding: 0 10px 0 0;
                            overflow: hidden;
                            max-height: 0px;
                            opacity: 0;
                            transition: opacity 0.2s linear 0s;
                            z-index : 2;
                        }
                    }
                }
            }
        }


        .row {
            position: relative;
            display : flex;
            align-items: center;
            justify-content: space-between;
            transition: padding 0.3s ease-in-out,
                        opacity 0.3s linear;

            &:hover {
                padding : 10px 0;
                opacity: 1;
            }

            &:nth-child(even) {
                background : #eee;
            }

            .icon {
                display: block;
                height: 40px;
                width: 40px;
                background: #ccc;

                &.hasIcon {
                    background: transparent;
                }

                &.pdf, &.doc, &.xls, &.ppt, &.zip {
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-color: transparent;
                }

                &.pdf { background-image: url(/assets/images/icons/pdf.png); }
                &.doc { background-image: url(/assets/images/icons/doc.png); }
                &.xls { background-image: url(/assets/images/icons/xls.png); }
                &.ppt { background-image: url(/assets/images/icons/ppt.png); }
                &.zip { background-image: url(/assets/images/icons/zip.png); }

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .reportTitle {
                margin: 0;
                line-height: 1.2em;
                font-size: 20px;
            }

            .col {
                padding: 12px 10px;

                &.img {
                    align-self: flex-start;
                    margin-top: 4px;
                }

                &.thumb {
                    width: 142px;
                    height: 80px;
                    img {
                        height: 100%;
                        width: 100%;
                    }
                }

                &.companyLogo {
                    span.logo {
                        height: 75px;
                        width: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
            
                    span.icon {
                        height: 75px !important;
                        width: 100px !important;
                    }
                }

                &.grow {
                    flex: 1;
                }

                &.jobType {
                    min-width: 100px;
                    text-align: center;
                }

                &.jobLocation {
                    min-width: 100px;
                    text-align: right;
                }

                a {
                    font-weight: bold;
                    display: block;
                    color: #0a6ca9;
                    text-decoration: none;
        
                    &:hover {
                        color: #142a51;
                    }


                    &:after {
                        position: absolute;
                        content: '';
                        height: 100%;
                        width: 100%;
                        left: 0;
                        top: 0;
                    }
                }

                strong {
                    display: block;
                }

                &.markdown {
                    p {
                        font-size: 13px;
                        margin: 5px 0 0 0;
                    }

                    .source {
                        font-size: 14px;
                        font-style: italic;
                        margin: 5px 0 8px 0;                        
                    }

                    li {
                        font-size: 13px;
                        padding: 0;
                        margin: 0;
                    }

                    ul, ol {
                        margin: 5px 0 0 15px;
                        padding: 0;
                    }
                }
            }
        }
    }
}

.block-table {   
    &.reportFiles {
        padding-top: 30px;
        padding-bottom: 30px;
        max-width: 580px;
    }

    .wrapper {
        &.default {
            position: relative;
    
            ::selection {
                color: none;
                background: none;
            }
            ::-moz-selection {
                color: none;
                background: none;
            }
        
            &:before {
                position: absolute;
                height: 100%;
                width: 100%;
                content: '';
                left: 0;
                top: 0;
                z-index: 9;
                -webkit-filter: blur(4px);
                filter: blur(4px);
                filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='4');
                opacity: 0.5;
            }
        
            .coming-soon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%) translateX(-50%) rotate(-3deg);
                left: 50%;
                z-index: 10;
                text-align : center;
                background: #ffda5d;
                padding: 20px;

                @include mobile {
                    width: 295px;
                    padding: 20px 5px;
                }
        
                h3 {
                    margin: 0 auto;
                }
        
                p {
                    margin-bottom: 0;
                }
            }
        
            &:hover {
                margin: 0px 0 !important;
                
                .row {
                    opacity: 0.5 !important;
                }
            }
    
            .row {
                -webkit-filter: blur(4px);
                filter: blur(4px);
                filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='4');
                opacity: 0.5;

                &:nth-child(2n) {
                    @include mobile {
                        display: none;

                    }
                }

                .col {
                    padding: 12px 10px;
    
                    @include mobile {
                        padding: 2px 5px;
                    }
                }

                &:hover {
                    padding : 0 !important;
                }
            }
        }
    }
}

.block-reportList {
    .button-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }

    padding-bottom: 50px;
}

.block-fileList,
.block-reportList,
.block-table {   
    .wrapper {
        display: flex;
        flex-direction: column;
        transition: margin 0.3s ease-in-out;

        &.tiles {
            justify-content: space-between;
            flex-wrap: wrap;
            flex-direction: row;
    
            .tile {
                width : calc( 33.33% - 20px );
                margin-bottom: 40px;
            }

            &:hover {
                margin: 0;
            }
        }


        &:hover {
            margin: -10px 0;
            
            .row {
                opacity: 0.45;
            }
        }

        .row {
            position: relative;
            display : flex;
            align-items: center;
            justify-content: space-between;
            transition: padding 0.3s ease-in-out,
                        opacity 0.3s linear;

            &:hover {
                padding : 10px 0;
                opacity: 1;
            }

            &:nth-child(even) {
                background : #eee;
            }

            .icon {
                display: block;
                height: 40px;
                width: 40px;
                background: #ccc;

                &.hasIcon {
                    background: transparent;
                }

                &.pdf, &.doc, &.xls, &.ppt, &.zip {
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-color: transparent;
                }

                &.pdf { background-image: url(/assets/images/icons/pdf.png); }
                &.doc { background-image: url(/assets/images/icons/doc.png); }
                &.xls { background-image: url(/assets/images/icons/xls.png); }
                &.ppt { background-image: url(/assets/images/icons/ppt.png); }
                &.zip { background-image: url(/assets/images/icons/zip.png); }

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .reportTitle {
                margin: 0;
                line-height: 1.2em;
                font-size: 20px;

                @include phablet-down {
                    font-size: 18px;
                }
            }

            .col-wrapper {
                display: flex;
                flex-grow : 1;
                width: 100%;
                padding: 10px 0;

                @include phablet-down {
                    flex-direction: column;
                }
            }

            .col {
                padding: 12px 10px;

                @include phablet-down {
                    padding: 2px 10px;
                }

                &.thumb {
                    width: 142px;
                    height: 80px;
                    img {
                        height: 100%;
                        width: 100%;
                    }
                }

                &.img {
                    @include phablet-down {
                        align-self: flex-start;

                        .icon {
                            margin-top: 12px;
                        }
                    }
                }

                &.grow {
                    flex: 1;

                    a {
                        display: block;
                        padding-top: 3px;
                        padding-bottom: 3px;
                    }
                }

                &.companyLogo {
                    span.logo {
                        height: 75px;
                        width: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
            
                    span.icon {
                        height: 75px !important;
                        width: 100px !important;
                    }
                }

                &.jobType {
                    min-width: 100px;
                    text-align: center;

                    @include phablet-down {
                        text-align: left;
                    }
                }

                &.jobLocation {
                    min-width: 100px;
                    text-align: right;

                    @include phablet-down {
                        text-align: left;
                    }
                }

                a {
                    font-weight: bold;
                    display: block;
                    color: #0a6ca9;
                    text-decoration: none;
        
                    &:hover {
                        color: #142a51;
                    }


                    &:after {
                        position: absolute;
                        content: '';
                        height: 100%;
                        width: 100%;
                        left: 0;
                        top: 0;
                    }
                }

                strong {
                    display: block;
                }
            }
        }
    }
}

.block-callout {
    padding-top: 30px;
    padding-bottom: 30px;

    .wrapper {
        background: var(--blue);
        padding: 35px 50px 30px 30px;
        text-align: center;
        text-align: left;
        position : relative;

        @include phablet-down {
            padding: 25px 20px 20px 15px;
        }       

        @include legacy {
            margin-right: -18px;
            margin-left: -18px;
        }

        @include desktop-up {
            margin-right: -30px;
            margin-left: -30px;
        }

        #ajax-content & {
            margin-right: 0px !important;
            margin-left: 0px !important;
        }
    }

    div.wrapper {
        & > h2:first-child, & > h3:first-child {
            background:var(--yellow);
            position: absolute;
            display: inline-block;
            top: -17px;
            left: 12px;
            margin: 0;
            padding: 6px 9px 4px;
            color: var(--blue);
        }

        h2, h3 {
            display: block;
            margin: 10px 0 20px;
            padding: 0;
            line-height: 1em;
            font-family: var(--thick);
            font-size: 22px;
            color: var(--brightBlue);
    
            @include mobile {
                font-size: 15px;
            }
        }


    }

    p, li {
        color: #fff;
        font-size: 22px;
        margin: 0;

        @include tablet {
            font-size: 20px;
            line-height: 1.4em;
        }

        @include mobile {
            font-size: 16px;
        }
        
        a {
            color: #fff;
            white-space: nowrap;

            &:hover {
                color: var(--brightBlue);
            }
        }
    }

    ul, ol {
        margin: 0 0 0 1em;
        padding: 0;

        li {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .button-wrapper {
        padding-top: 16px;

        @include mobile {
            padding-top: 8px;
        }
    }


}


.block-search {
    max-width: 640px;
    padding: 20px 0 30px;

    .row {
        display: flex;
        align-items: flex-end;
        margin-bottom: 8px;

        .input {
            flex: 1;
            input[type=search] {
                width: calc( 100% + 1px );
                padding: 8px 12px;
                border: 1px solid #888;
                line-height: 1em;
                font-size: 18px;
            }
        }

        button {
            background: #f11d45;
            border: 1px solid #f11d45;
            color: #fff;
            display: inline-block;
            padding: 8px 12px;
            font-weight: bold;
            text-decoration: none;
            line-height: 1em;
            font-size: 18px;
            border: none;
            height: 39px;
            cursor: pointer;
            text-transform: uppercase;
        }
    }

    ul.searchResults {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            margin: 0 0 40px 0;
            padding: 0;

            a {
                display: block;

                &.url {
                    font-size: 11px;
                    transform: translateY(-5px);
                }

                &.title {
                    text-decoration: none;
                }
            }

            p.summary {
                font-size: 15px;

                b {
                    display: inline-block;
                    margin: 0 3px;
                    background-color: #FFFFBF;
                    padding: 0 6px;
                }
            }
        }
    }

    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            color: var(--blue);
        }
    }
}

.block-newsletter {

    max-width: 640px;
    padding: 20px 0 30px;

    .row {
        display: flex;
        align-items: flex-end;
        margin-bottom: 8px;

        .field-input,
        .input {
            flex: 1;
            input[type=password],
            input[type=email] {
                width: calc( 100% - 24px );
                padding: 8px 12px;
                border: 1px solid #888;
                line-height: 1em;
                font-size: 18px;
                // height: 22px;
            }
        }

        .signup input,
        button {
            background: #f11d45;
            border: 1px solid #f11d45;
            color: #fff;
            display: inline-block;
            padding: 8px 12px;
            font-weight: bold;
            text-decoration: none;
            line-height: 1em;
            font-size: 18px;
            border: none;
            height: 40px;
            cursor: pointer;
        }
    }

    .legal {
        font-size: 12px;
    
        a {
            color: #0a6ca9;
            font-weight: bold;

            &:hover {
                color: #142a51;
            }
        }
    }
}


.block-map {
    h2.block {
        max-width: 340px;
        line-height: 1.2em;
    }

    div.text {
        padding-bottom: 30px;

        p {
            max-width: 560px;
        }
    }
}


.block-calendar {
    position : relative;
}

.block-login {
    .wrapper {
        display: flex;
        justify-content: space-between;

        @include mobile {
            flex-direction: column;
        }
    }

    .salesforce {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            height: 100%;
            right: -25px;
            top: 0;
            display: block;
            //border-right: 2px solid var(--grey);

            @include mobile {
                display: none;
            }
        }
    }

    .salesforce,
    .hockeystick {
        width: 45%;
        //width: 90%;

        @include mobile {
            width: 100%;
            margin-bottom: 60px;
        }

        .logo {
            display: flex;
            height: 80px;
            margin-bottom: 10px;
            align-items: flex-end;

            img {
                max-height: 100%;
                max-width: 240px;
            }
        }

        h2 {
            margin-top: 0;
        }
    }

    p {
        font-size: 16px;
        line-height: 1.3em;
        margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    a.button,
    button {
        margin-top: 16px;
        background:#f11d45;
        color: #fff;
        display: inline-block;
        padding : 8px 12px;
        border-radius: 2px;
        font-weight: bold;
        text-decoration: none;
        line-height: 1em;
        font-size: 17px;
        border: none;
    }
}

.block-contactUs {
    .wrapper {
        @include mobile {
            flex-direction: column !important;
        }
    }

    div.social {
        display: flex;
        margin-top: 25px;

        @include mobile {
            margin-bottom: 5px;
        }

        @include legacy-up {
            margin-bottom: 30px;
        }

        a {
            text-decoration: none;
            display: block;
            height: 24px;
            width: 24px;
            margin-right: 10px;

            &:hover {
                svg {
                    fill: var(--brightBlue);
                }
            }
        }

        span {
            display: block;
        }

        svg {
            fill: var(--blue);
        }
    }

    .directions {
        font-size: 16px;

        @include mobile {
            font-size: 14px;
        }
    }

    .image.map {
        min-width: 760px !important;
        max-width: 760px !important;

        @include tablet {
            min-width: 500px !important;
            max-width: 500px !important;
        }

        @include phablet {
            min-width: 300px !important;
            max-width: 400px !important;
        }

        @include mobile {
            min-width: 100% !important;
            max-width: 100% !important;
            margin-top: 20px;
        }
    }

    .map-wrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
        margin: 8px 0 0 ;
    
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

#ajax-content div.jobDetails {
    flex-wrap: wrap;
    padding: 20px 0 0 20px;

    @include phablet-down {
        padding: 12px 0 0 12px;
    }
        

    div {
        white-space: nowrap;
        padding-bottom: 20px;

        @include phablet-down {
            white-space: normal;
            margin-bottom: 0;
        }
    }
}

div.c-eventDetails,
div.c-jobDetails {

    display: flex;
    background: var(--opaqueBlue);
    padding: 20px 0 20px 20px;
    margin: 0 -20px 40px;
    justify-content: space-between;

    @include tablet-down {
        padding: 12px 0 10px 12px;
        margin: 0 0px 20px;
    }

    @include phablet-down {
        flex-direction: column;
    }

    div {
        @include phablet-down {
            margin-bottom: 25px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        strong {
            text-transform: uppercase;
            display: block;
            font-size: 13px;
            margin-bottom: 3px;
        }

        a {
            font-weight: bold;
            display: block;
            color: #0a6ca9;
            text-decoration: none;

            &:hover {
                color: #142a51;
            }
        }

        font-size: 22px;
        margin-right: 30px;
    }

    div.social {
        div.icons {
            display: flex;
            margin-top: 4px;
             
            a {
                text-decoration: none;
                display: block;
                height: 20px;
                width: 20px;
                margin-right: 10px;
        
                &:hover {
                    svg {
                        fill: var(--brightBlue);
                    }
                }
            }
        
            span {
                display: block;
            }
        
            svg {
                fill: var(--blue);
            }
        }
    }
}