:root {
    --dark: #231F20;
    --grey: #E6E7E8;
    --white: #ffffff;
    --background: #f3f3f3;

    --blue: #152A50;
    --lightBlue: #206FE2;
    --brightBlue: #00AEFF;
    --darkBlue: rgba(3, 24, 62, 0.5);
    --opaqueBlue:rgba(55, 119, 188, 0.23);
    --azure: #3777BC;
    
    --purple: #563BB3;

    --red: #F21D44;
    --darkRed: #b11c21;
    
    --yellow: #FFDA5D;
    --paleYellow: #ffe89d;
    --darkYellow: #dfb21e;

    --green:#2d8515;

    --headline:'bebas-neue';
    --thick:'proxima-nova-black';
    
    --text:'proxima-nova', 'Montserrat', 'Lato', system-ui, BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    
    --ease: cubic-bezier(0.16, 1, 0.3, 1);

    --shadow-color: 220deg 34% 53%;
    --shadow-elevation-low:
      0px 0.8px 0.9px hsl(var(--shadow-color) / 0.41),
      0px 1.3px 1.4px -1.4px hsl(var(--shadow-color) / 0.38),
      0px 3.3px 3.6px -2.7px hsl(var(--shadow-color) / 0.36);
    --shadow-elevation-medium:
      0px 0.8px 0.9px hsl(var(--shadow-color) / 0.43),
      0px 2.6px 2.8px -0.9px hsl(var(--shadow-color) / 0.41),
      0px 6.7px 7.3px -1.8px hsl(var(--shadow-color) / 0.39),
      -0.1px 16.7px 18.2px -2.7px hsl(var(--shadow-color) / 0.37);
    --shadow-elevation-high:
      0px 0.8px 0.9px hsl(var(--shadow-color) / 0.4),
      0px 4.6px 5px -0.4px hsl(var(--shadow-color) / 0.39),
      -0.1px 8.7px 9.5px -0.8px hsl(var(--shadow-color) / 0.38),
      -0.1px 14.7px 16px -1.2px hsl(var(--shadow-color) / 0.37),
      -0.1px 24px 26.1px -1.6px hsl(var(--shadow-color) / 0.36),
      -0.2px 38.1px 41.4px -2px hsl(var(--shadow-color) / 0.35),
      -0.3px 58.6px 63.7px -2.4px hsl(var(--shadow-color) / 0.34),
      -0.5px 87px 94.6px -2.7px hsl(var(--shadow-color) / 0.34);    
}
// Breakpoints
// -------------------------
$widescreen-width  : 1600px;
$desktop-width     : 1280px;
$legacy-width      : 1000px;
$drawer-menu-width : 1020px;
$tablet-width      : 760px;
$mobile-width      : 576px;
$small-mobile-width: 360px;

@mixin widescreen {
    @media screen and (min-width: #{$widescreen-width}) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: #{$desktop-width}) and (max-width: #{$widescreen-width - 1px}) {
        @content;
    }
}

@mixin desktop-up {
    @media screen and (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin legacy {
    @media screen and (min-width: #{$legacy-width}) and (max-width: #{$desktop-width - 1px }) {
        @content;
    }
}

@mixin legacy-up {
    @media screen and (min-width: #{$legacy-width}) {
        @content;
    }
}

@mixin legacy-down {
    @media screen and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin drawer-menu {
    @media screen and (max-width: #{$drawer-menu-width}) {
        @content;
    }
}

@mixin tablet-up {
    @media screen and (min-width: #{$mobile-width + 1px}) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: #{$mobile-width + 1px}) and (max-width: #{$legacy-width - 1px}) {
        @content;
    }
}

@mixin tablet-down {
    @media screen and (max-width: #{$legacy-width - 1px}) {
        @content;
    }
}

@mixin phablet {
    @media screen and (min-width: #{$mobile-width + 1px}) and (max-width: #{$tablet-width}) {
        @content;
    }
}

@mixin phablet-down {
    @media screen and (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: #{$mobile-width}) {
        @content;
    }
}

@mixin tiny {
    @media screen and (max-width: #{$small-mobile-width}) {
        @content;
    }
}

@mixin laptop {
    @media screen 
      and (min-device-width: 1200px) 
      and (max-device-width: 1600px)
      and (max-device-height: 800px) 
      and (-webkit-min-device-pixel-ratio: 1) {
        @content;
      }
}
  
.no-tablet {
    @include tablet {
        display: none !important;
    }
}
  
.no-mobile {
    @include mobile {
        display: none !important;
    }
}
  
.only-mobile {
    @include tablet-up {
        display: none !important;
    }
}



















































































































































































.c-canadaMap {
    padding: 5rem 0;
    .wrapper {
        padding: 0px 0 0;
        display: flex;
        position: relative;
        min-height: 490px;
        max-height: 490px;
        height: 100%;

        @include mobile {
            min-height: 470px;
            max-height: 470px;
        }

        @include tiny {
            min-height: 400px;
            max-height: 400px;
        }

        @include desktop-up {
            top: -30px;
            margin-right: 0px;
            margin-left: 0px;
        }

        @include tablet {
            max-height: 600px;
        }

        .toggler {
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            width: 360px;
            opacity: 0;
            z-index: 10;
            transition: opacity 0s linear 0s;

            @include tablet-down {
                opacity: 1;
                top: -15px;
            }

            @include legacy-up {
                top: 0px;
            }

            @include desktop-up {
                left: 70px;
                top: 20px;
            }

            @include mobile {
                max-width: calc(100% - 20px);
            }

            a {
                display: block;
                padding: 15px;
                background: var(--grey);
                color: #222;
                font-weight: 700;
                text-decoration: none;
                opacity: 0.65;
                text-transform: uppercase;

                @include phablet-down {
                    max-width: 80px;
                    font-size: 14px;
                }

                @include mobile {
                    max-width: 120px;
                }

                &.active {
                    background: var(--blue);
                    color: #fff;
                    opacity: 1;
                }
            }
        }

        &.large,
        &:hover {
            div.source,
            .see-more,
            .toggler {
                opacity: 1;
                transition: opacity 0.3s linear 0.3s;
            }

            @include legacy-up {
                .map-wrapper {
                    width: 83%;
        
                    .map {
                        svg {
                            filter: drop-shadow( -20px 40px 8px rgba(0, 0, 0, .4));
        
                            transform: rotateX(45deg)
                                    rotate(-10deg)
                                    scale(1.10, 1.35)
                                    translateX(80px)
                                    translateY(-60px);
                        }
                    }
                }
            }
        }

        .map-wrapper {
            perspective: 1400px;
            perspective-origin: 20% 120%;
            width: 70%;
            transition: width 0.3s ease-in-out;

            @include tablet {
                width: 90%;
                margin-top: 60px;
            }

            @include mobile {
                width: 100%;
                margin-top: 50px;
            }

            svg {
                transition: transform 0.3s ease-in-out,
                            filter 0.2s ease-in-out 0.15s;

                .province {
                    path {
                        stroke: #fff;
                        stroke-width: 3px;
                    }
                }

                .province {
                    &:hover {
                        fill : var(--bright-blue);
                    }

                    &.active {
                        fill : var(--yellow) !important;
                    }
                }

                .province {
                    cursor: pointer;
                    fill: var(--azure);
                    transition: fill 0.2s ease-in-out;

                    &.inactive {
                        fill: var(--blue);

                        &:hover {
                            fill: #363636 !important;
                        }

                        &.active {
                            fill: var(--grey) !important;
                        }

                    }
                }
            }
        }

        .text {
            position: absolute;
            top: 0px;
            right: 50px;
            background: var(--blue);
            opacity: 1;
            transition: opacity 0.25s linear 0.25s;
            padding: 20px;
            width: 450px;

            @include tablet {
                right: 0px;
            }

            @include phablet {
                width: 350px;
                padding: 14px;
            }

            @include mobile {
                top: inherit;
                bottom: 0;
                right: inherit;
                left: 0;
                width: 260px;
                padding: 10px;
            }

            &.inactive {
                background: var(--grey);

                div.province {
                    color: var(--blue);
                }

                h3 {
                    color: #666;
                }

                p {
                    color: #444;
                }

                p.stats {
                    display: none;
                }
            }

            div.type {
                margin-bottom : 5px;
                font-family: var(--text);
                font-weight: bold;
                color: var(--bright-blue);
                font-size: 14px;
                line-height: 1em;
                display: block;
                text-transform: uppercase;
            }

            h3 {
                color: #fff;
            }

            p {
                margin-bottom: 0;
                color: #fff;
                font-size: 20px;
                line-height: 1.3em;

                strong {
                    color: #00aeef;
                }

                &.firms {
                    margin-top: 9px;
                }
            }
        }
    }

    .tableWrapper {
        display: flex;
        justify-content: space-between;
        max-height: 0px;
        opacity: 0;
        overflow: hidden;
        margin-top: -50px;

        animation: revealDown;
        animation-fill-mode: forwards;
        animation-duration: 0.5s;
        animation-delay: 0s;

        @include tablet-down {
            flex-direction: column;
            margin-bottom: -50px;
        }

        &.vc {
            @include tablet-down {
                flex-direction: column-reverse;
            }
        }

        @include mobile {
            padding-top: 100px;
        }

        @include desktop-up {
            margin-left: -140px;
            margin-right: -140px;
            justify-content: space-between;
            margin-top: 30px;
        }
    }

    .dataTable {
        position: relative;
        z-index: 2;
        margin-bottom: 50px;
        transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
        
        @include legacy-up {
            max-width: 48%;
            margin-bottom: 20px;
        }

        @include mobile {
            overflow-x: scroll;
        }

        &.active {
            margin-top: -10px;
            
            h3 {
                opacity: 1;

                @include legacy-up {
                    padding-bottom: 20px;
                }
            }

            table {
                tr.active {
                    background: var(--yellow);
                }
            }
        }

        h3 {
            color: #fff;
            background: var(--bright-blue);
            padding: 10px 15px;
            display: inline-block;
            transform: translateX(20px);
            margin-bottom: 0;
            transition: padding 0.3s ease-in-out, opacity 0.3s ease-in-out;

            @include legacy {
                font-size: 18px;
            }

            @include legacy-up {
                opacity: 0.5;
            }

            @include mobile {
                padding: 8px 10px;
                font-size: 16px;
            }
        }
    
        table {
            width: 100%;
            max-width: 740px;
            border: 1px solid #777;
    
            th, td {
                padding: 10px 20px;
                margin: 0;
                text-align : right;

                @include legacy {
                    padding: 10px;
                }

                @include mobile {
                    padding: 8px;
                }
            }
    
            th {
                text-transform: uppercase;
                font-size: 18px;
                padding: 15px 20px;
                font-family: var(--thick);
                font-weight: normal;
                vertical-align: bottom;

                @include desktop {
                    font-size: 16px;
                }

                @include legacy {
                    font-size: 14px;
                    padding: 15px 10px;
                }
    
                @include mobile {
                    font-size: 13px;
                    padding: 8px;
                    font-family: var(--text);
                }

                @include tiny {
                    padding: 8px 4px;
                    font-size: 12px;
                }

                &:first-of-type {
                    text-align: left;
                }
            }
    
            td {
                font-size: 22px;

                @include legacy {
                    font-size: 18px;
                }

                @include mobile {
                    font-size: 13px;
                }

                @include tiny {
                    font-size: 11px;
                }

                &:first-of-type {
                    text-align: left;
                }
            }
    
            thead {
                background: var(--blue);
                color: #fff;
            }
    
            tbody {
                tr {
                    transition: background 0.3s ease-in-out;
                    &:nth-child(2n+1) {
                        background: var(--grey);
                    }
                }
            }

            tfoot {
                tr {
                    background: #363636;
                    color: #fff;

                    td {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    div.source {
        font-size: 11px;
        left: 10%;
        position: absolute;
        bottom: 15px;
        opacity: 0;

        @include legacy {
            bottom: 80px;
        }

        @include tablet {
            opacity: 1 !important;
            left: 40%;
            transform: translateX(-50%);
            bottom: -25px;
        }

        @include phablet {
            left: 20px;
            bottom: 50px;
            transform: translateX(0%);
        }

        @include mobile {
            opacity: 1 !important;
            left: 10px;
            bottom: -50px;
            transform: translateX(0%);
        }
    }

    p.source {
        text-align: center;
    }
}
