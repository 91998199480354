:root {
    --dark: #231F20;
    --grey: #E6E7E8;
    --white: #ffffff;
    --background: #f3f3f3;

    --blue: #152A50;
    --lightBlue: #206FE2;
    --brightBlue: #00AEFF;
    --darkBlue: rgba(3, 24, 62, 0.5);
    --opaqueBlue:rgba(55, 119, 188, 0.23);
    --azure: #3777BC;
    
    --purple: #563BB3;

    --red: #F21D44;
    --darkRed: #b11c21;
    
    --yellow: #FFDA5D;
    --paleYellow: #ffe89d;
    --darkYellow: #dfb21e;

    --green:#2d8515;

    --headline:'bebas-neue';
    --thick:'proxima-nova-black';
    
    --text:'proxima-nova', 'Montserrat', 'Lato', system-ui, BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    
    --ease: cubic-bezier(0.16, 1, 0.3, 1);

    --shadow-color: 220deg 34% 53%;
    --shadow-elevation-low:
      0px 0.8px 0.9px hsl(var(--shadow-color) / 0.41),
      0px 1.3px 1.4px -1.4px hsl(var(--shadow-color) / 0.38),
      0px 3.3px 3.6px -2.7px hsl(var(--shadow-color) / 0.36);
    --shadow-elevation-medium:
      0px 0.8px 0.9px hsl(var(--shadow-color) / 0.43),
      0px 2.6px 2.8px -0.9px hsl(var(--shadow-color) / 0.41),
      0px 6.7px 7.3px -1.8px hsl(var(--shadow-color) / 0.39),
      -0.1px 16.7px 18.2px -2.7px hsl(var(--shadow-color) / 0.37);
    --shadow-elevation-high:
      0px 0.8px 0.9px hsl(var(--shadow-color) / 0.4),
      0px 4.6px 5px -0.4px hsl(var(--shadow-color) / 0.39),
      -0.1px 8.7px 9.5px -0.8px hsl(var(--shadow-color) / 0.38),
      -0.1px 14.7px 16px -1.2px hsl(var(--shadow-color) / 0.37),
      -0.1px 24px 26.1px -1.6px hsl(var(--shadow-color) / 0.36),
      -0.2px 38.1px 41.4px -2px hsl(var(--shadow-color) / 0.35),
      -0.3px 58.6px 63.7px -2.4px hsl(var(--shadow-color) / 0.34),
      -0.5px 87px 94.6px -2.7px hsl(var(--shadow-color) / 0.34);    
}
// Breakpoints
// -------------------------
$widescreen-width  : 1600px;
$desktop-width     : 1280px;
$legacy-width      : 1000px;
$drawer-menu-width : 1020px;
$tablet-width      : 760px;
$mobile-width      : 576px;
$small-mobile-width: 360px;

@mixin widescreen {
    @media screen and (min-width: #{$widescreen-width}) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: #{$desktop-width}) and (max-width: #{$widescreen-width - 1px}) {
        @content;
    }
}

@mixin desktop-up {
    @media screen and (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin legacy {
    @media screen and (min-width: #{$legacy-width}) and (max-width: #{$desktop-width - 1px }) {
        @content;
    }
}

@mixin legacy-up {
    @media screen and (min-width: #{$legacy-width}) {
        @content;
    }
}

@mixin legacy-down {
    @media screen and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin drawer-menu {
    @media screen and (max-width: #{$drawer-menu-width}) {
        @content;
    }
}

@mixin tablet-up {
    @media screen and (min-width: #{$mobile-width + 1px}) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: #{$mobile-width + 1px}) and (max-width: #{$legacy-width - 1px}) {
        @content;
    }
}

@mixin tablet-down {
    @media screen and (max-width: #{$legacy-width - 1px}) {
        @content;
    }
}

@mixin phablet {
    @media screen and (min-width: #{$mobile-width + 1px}) and (max-width: #{$tablet-width}) {
        @content;
    }
}

@mixin phablet-down {
    @media screen and (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: #{$mobile-width}) {
        @content;
    }
}

@mixin tiny {
    @media screen and (max-width: #{$small-mobile-width}) {
        @content;
    }
}

@mixin laptop {
    @media screen 
      and (min-device-width: 1200px) 
      and (max-device-width: 1600px)
      and (max-device-height: 800px) 
      and (-webkit-min-device-pixel-ratio: 1) {
        @content;
      }
}
  
.no-tablet {
    @include tablet {
        display: none !important;
    }
}
  
.no-mobile {
    @include mobile {
        display: none !important;
    }
}
  
.only-mobile {
    @include tablet-up {
        display: none !important;
    }
}




































































































@keyframes menuIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

.c-mobileMenu {
    position: absolute;
    z-index: 1000000;
    min-width: 100vw;
    top: 0;

    nav {
        opacity: 0;
        max-height: 100vh;
        overflow: hidden;
        z-index: 1000;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.5s ease-in-out;
        background: var(--blue);
        animation-name: menuIn;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        .container.flex {
            display: flex;
            justify-content: space-between;
            padding: 1.5rem 0;
            align-items: center;
        }


        .logo {
            
            img {
                max-height: 40px;
            }

            @include tiny {
                padding-left: 10px;
            }
        }

        ul.primary {
            margin: 4rem 0 0 0;
            padding: 15px 0px;
            list-style: none;

            &.utility {
                border-bottom: none;
                margin-top: 0rem;
            }

            @include mobile {
                padding: 8px 0 0 10px;
            }

            li {
                margin: 0;
                padding: 0;
                position: relative;
                max-width: 330px;
                display: block;

                @include mobile {
                    max-width: 280px;
                }

                @include tiny {
                    max-width: 240px;
                }

                a:not(.menu) {
                    font-size: 2rem;
                    text-decoration: none;
                    display: block;
                    color: #fff;
                    margin: 0 0 0rem;
                    font-weight: 900;
                    line-height: 1em;
                    font-family: var(--headline);
                    text-transform: uppercase;
                    padding : 0.75rem;

                    @include mobile {
                        font-size: 2rem;
                    }

                    &:hover, &:focus {
                        color: var(--yellow);
                    }
                }

                a.menu {
                    display: inline-block;
                    padding: 10px;
                    font-size: 18px;
                    color: #fff;
                    text-decoration: none;
                    position: relative;
                    font-family: var(--headline);
                    font-weight: 700;
                    text-transform: uppercase;

                    &.member {
                        background: #EE2D46;
                        color: #162A50;
                        padding: 2rem 2.5rem;
                        font-size: 2.5rem;
                        color: #fff;
                    }

                    @include tiny {
                        padding: 8px 10px;
                        font-size: 16px;
                        margin-bottom: 6px;
                    }

                    &:hover, &:active {
                        background: darken( #29ABE2, 10% );
                    }

                    .chevron {
                        position: absolute;
                        right: 20px;
                        top: 10px;
                        font-size: 26px;
                    }
                }
            }
        }
    }
}

.buttonWrapper {
    margin-right: 3rem;
}

a.button {
    display: flex;
    background: #EE2D46;
    color: #fff;
    font-family: var(--text);
    text-transform: uppercase;
    height: 34px;
    font-weight: 700;
    padding: 0 2rem;
    text-decoration: none;
    text-align: center;
    border: none;
    cursor: pointer;
    align-items: center;
    font-size: 1.4rem;

    &:hover, &:focus {
        background: #29ABE2;
        color: #fff;
    }
}

.toggle {
    z-index   : 1001;
    display   : block;
    height    : 24px;
    width     : 30px;
    position  : absolute;
    right     : 40px;
    top       : 45px;

    @include tiny {
        right: 10px;
    }

    @include legacy-up {
        display: none;
    }

    span {
        height          : 4px;
        width           : 30px;
        position        : absolute;
        left            : 0;
        top             : 0;
        background-color: #ffffff;
        transition      : all 0.3s ease-in-out;

        &:nth-child(1) {
            body.has-mobile-menu & {
                background-color: transparent;
            }
        }

        &:nth-child(2) {
            top: calc( 50% - 2px );

            body.has-mobile-menu & {
                top   : 10px;
                transform: rotate(-45deg);
            }
        }

        &:nth-child(3) {
            bottom: 0;
            top   : auto;

            body.has-mobile-menu & {
                transform: rotate(45deg);
                bottom: auto;
                top   : 10px;
            }
        }
    }
}
