footer.base-footer {
    background-color: var(--blue);
    padding: 20px;
    color: #fff;

    .wrapper {
        display: flex;
        justify-content: space-between;

        @include tablet-down {
            flex-direction : column;
        }        
    }
}