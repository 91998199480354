.skip-link {
    background: #000;
    position: absolute;
    transform: translateY(-100%);
    width: 100%;
    left: 0;
    z-index: 10000;
    text-align: center;
    padding: 8px 0;
    color: #fff;
    opacity: 1 !important;

    a {
       display: inline-block;
       color: #fff;
       font-weight: 700;
       padding: 4px;    
       text-decoration: none;
    }
}

.skip-link:focus-within,
.skip-link:focus {
    transform: translateY(0%);
    transition: transform 0.3s;
}

.sr-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
}