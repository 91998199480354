:root {
    --dark: #231F20;
    --grey: #E6E7E8;
    --white: #ffffff;
    --background: #f3f3f3;

    --blue: #152A50;
    --lightBlue: #206FE2;
    --brightBlue: #00AEFF;
    --darkBlue: rgba(3, 24, 62, 0.5);
    --opaqueBlue:rgba(55, 119, 188, 0.23);
    --azure: #3777BC;
    
    --purple: #563BB3;

    --red: #F21D44;
    --darkRed: #b11c21;
    
    --yellow: #FFDA5D;
    --paleYellow: #ffe89d;
    --darkYellow: #dfb21e;

    --green:#2d8515;

    --headline:'bebas-neue';
    --thick:'proxima-nova-black';
    
    --text:'proxima-nova', 'Montserrat', 'Lato', system-ui, BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    
    --ease: cubic-bezier(0.16, 1, 0.3, 1);

    --shadow-color: 220deg 34% 53%;
    --shadow-elevation-low:
      0px 0.8px 0.9px hsl(var(--shadow-color) / 0.41),
      0px 1.3px 1.4px -1.4px hsl(var(--shadow-color) / 0.38),
      0px 3.3px 3.6px -2.7px hsl(var(--shadow-color) / 0.36);
    --shadow-elevation-medium:
      0px 0.8px 0.9px hsl(var(--shadow-color) / 0.43),
      0px 2.6px 2.8px -0.9px hsl(var(--shadow-color) / 0.41),
      0px 6.7px 7.3px -1.8px hsl(var(--shadow-color) / 0.39),
      -0.1px 16.7px 18.2px -2.7px hsl(var(--shadow-color) / 0.37);
    --shadow-elevation-high:
      0px 0.8px 0.9px hsl(var(--shadow-color) / 0.4),
      0px 4.6px 5px -0.4px hsl(var(--shadow-color) / 0.39),
      -0.1px 8.7px 9.5px -0.8px hsl(var(--shadow-color) / 0.38),
      -0.1px 14.7px 16px -1.2px hsl(var(--shadow-color) / 0.37),
      -0.1px 24px 26.1px -1.6px hsl(var(--shadow-color) / 0.36),
      -0.2px 38.1px 41.4px -2px hsl(var(--shadow-color) / 0.35),
      -0.3px 58.6px 63.7px -2.4px hsl(var(--shadow-color) / 0.34),
      -0.5px 87px 94.6px -2.7px hsl(var(--shadow-color) / 0.34);    
}
// Breakpoints
// -------------------------
$widescreen-width  : 1600px;
$desktop-width     : 1280px;
$legacy-width      : 1000px;
$drawer-menu-width : 1020px;
$tablet-width      : 760px;
$mobile-width      : 576px;
$small-mobile-width: 360px;

@mixin widescreen {
    @media screen and (min-width: #{$widescreen-width}) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: #{$desktop-width}) and (max-width: #{$widescreen-width - 1px}) {
        @content;
    }
}

@mixin desktop-up {
    @media screen and (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin legacy {
    @media screen and (min-width: #{$legacy-width}) and (max-width: #{$desktop-width - 1px }) {
        @content;
    }
}

@mixin legacy-up {
    @media screen and (min-width: #{$legacy-width}) {
        @content;
    }
}

@mixin legacy-down {
    @media screen and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin drawer-menu {
    @media screen and (max-width: #{$drawer-menu-width}) {
        @content;
    }
}

@mixin tablet-up {
    @media screen and (min-width: #{$mobile-width + 1px}) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: #{$mobile-width + 1px}) and (max-width: #{$legacy-width - 1px}) {
        @content;
    }
}

@mixin tablet-down {
    @media screen and (max-width: #{$legacy-width - 1px}) {
        @content;
    }
}

@mixin phablet {
    @media screen and (min-width: #{$mobile-width + 1px}) and (max-width: #{$tablet-width}) {
        @content;
    }
}

@mixin phablet-down {
    @media screen and (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: #{$mobile-width}) {
        @content;
    }
}

@mixin tiny {
    @media screen and (max-width: #{$small-mobile-width}) {
        @content;
    }
}

@mixin laptop {
    @media screen 
      and (min-device-width: 1200px) 
      and (max-device-width: 1600px)
      and (max-device-height: 800px) 
      and (-webkit-min-device-pixel-ratio: 1) {
        @content;
      }
}
  
.no-tablet {
    @include tablet {
        display: none !important;
    }
}
  
.no-mobile {
    @include mobile {
        display: none !important;
    }
}
  
.only-mobile {
    @include tablet-up {
        display: none !important;
    }
}


































































































































































































































.create-fund {
    max-width: 540px !important;
}

div.saving {
    opacity: 0.4;
    transition: opacity 0.3s linear;
}
