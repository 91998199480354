.subnav {
    background:#eee;
    padding: 0px 10px;
    position:sticky;
    top: 79px;
    z-index: 200;
    text-align: center;
    border-bottom: 5px solid #fff;
    margin-bottom: -15px;
    height: 45px;
    display: flex;
    align-items: center;

    @include mobile {
        display: none;
    }

    @include phablet-down {
        padding: 0;
    }

    #ajax-content & {
        display: none;
    }

    ul {
        margin : 0 auto;
        padding: 0;
        list-style: none;
        display: flex;

        li {
            margin : 0;
            padding: 0;
        }

        &.sub {
            justify-content: center;

            li {
                @include tablet {
                    display: flex;
                    align-items: center;
                    max-width: 25%;
                }

                @include phablet {
                    max-width: 30%;
                }
            }

            a {
                color: #444;
                display: inline-block;
                margin : 0 16px;
                font-size: 18px;
                font-weight: 600;
                text-decoration: none;

                @include tablet {
                    font-size: 16px;
                    margin : 0 15px;
                }

                @include phablet {
                    font-size: 14px;
                    font-weight: 400;
                }
        
                &:hover {
                    color: #0a6ca9
                }
            }
        }

        &.crumbs {
            max-width: 940px;

            li {
                &:last-child {
                    a {
                        margin-right: 0;
                        &:after {
                            display: none;
                        }
                    }
                }
            }
            a {
                color: #444;
                display: inline-block;
                margin : 0 12px 0 0;
                font-size: 15px;
                text-decoration: none;

                &:after {
                    content: '>';
                    font-size: 10px;
                    margin-left: 8px;
                }
        
                &:hover {
                    color: #0a6ca9
                }

                @include phablet {
                    font-size: 13px;
                    font-weight: 400;
                }
            }
        }
    }
}