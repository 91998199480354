// Breakpoints
// -------------------------
$widescreen-width  : 1600px;
$desktop-width     : 1280px;
$legacy-width      : 1000px;
$drawer-menu-width : 1020px;
$tablet-width      : 760px;
$mobile-width      : 576px;
$small-mobile-width: 360px;

@mixin widescreen {
    @media screen and (min-width: #{$widescreen-width}) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: #{$desktop-width}) and (max-width: #{$widescreen-width - 1px}) {
        @content;
    }
}

@mixin desktop-up {
    @media screen and (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin legacy {
    @media screen and (min-width: #{$legacy-width}) and (max-width: #{$desktop-width - 1px }) {
        @content;
    }
}

@mixin legacy-up {
    @media screen and (min-width: #{$legacy-width}) {
        @content;
    }
}

@mixin legacy-down {
    @media screen and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin drawer-menu {
    @media screen and (max-width: #{$drawer-menu-width}) {
        @content;
    }
}

@mixin tablet-up {
    @media screen and (min-width: #{$mobile-width + 1px}) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: #{$mobile-width + 1px}) and (max-width: #{$legacy-width - 1px}) {
        @content;
    }
}

@mixin tablet-down {
    @media screen and (max-width: #{$legacy-width - 1px}) {
        @content;
    }
}

@mixin phablet {
    @media screen and (min-width: #{$mobile-width + 1px}) and (max-width: #{$tablet-width}) {
        @content;
    }
}

@mixin phablet-down {
    @media screen and (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: #{$mobile-width}) {
        @content;
    }
}

@mixin tiny {
    @media screen and (max-width: #{$small-mobile-width}) {
        @content;
    }
}

@mixin laptop {
    @media screen 
      and (min-device-width: 1200px) 
      and (max-device-width: 1600px)
      and (max-device-height: 800px) 
      and (-webkit-min-device-pixel-ratio: 1) {
        @content;
      }
}
  
.no-tablet {
    @include tablet {
        display: none !important;
    }
}
  
.no-mobile {
    @include mobile {
        display: none !important;
    }
}
  
.only-mobile {
    @include tablet-up {
        display: none !important;
    }
}