.pagination {
    display: flex;
    background: #eee;
    padding: 10px;
    justify-content: center;

    a {
        display: inline-block;
        margin: 0 15px;
        color: #0a6ca9;
        font-weight: bold;

        &:hover {
            color: #142a51;
        }

        &.prev {
            &::before {
                content: '‹';
                display: inline-block;
                margin-right: 3px;
            }
        }

        &.next {
            &::after {
                content: '›';
                display: inline-block;
                margin-left: 3px;
            }
        }       
    }
}