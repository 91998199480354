#app:not(.hidden) {
    opacity: 1 !important;
    transition: opacity 0.3s ease-out 0.5s;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    z-index: 10;

    @include phablet-down {
        overflow-x:hidden
    }
}

main[role=main] {
    border-radius: 1rem;
    padding: 1rem 2rem 2rem;
    min-height: 50rem;
    margin-bottom: 10rem;
}

.page-content {
    margin-top: 60px;
    @include tablet-down {
        margin-top: 35px;
    }
}

.container.wider {
    max-width: 1200px;
}

.breadcrumb {
    margin-bottom: 2.5rem !important;
}

.has-background-white-ter {
    background: #e3ebee !important;   
}

.tile.stat {
    background: #00aeef;

    p {
        color: #fff;
    }
}

a.tile.stat {
    &:hover {
        background: #162a50;       
    }
}

.button.is-small.is-primary {
    background: #ffda5d;
    color: #000;

    &:hover {
        background: #162a50;
        color: #fff;
    }
}