.modal-content {
    max-width: 480px !important;

    .modal-title {
        background-color: #ffda5d;
        padding: 1rem;
        color: #162a50;
        margin-bottom: 0 !important;
    }

    .box {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
}


.modal-close {
    &:hover {
        &::after, &::before {  
            background-color: #f14668;
        }
    }
}