main.checkout {

    .notification {
        font-size: 16px !important;
    }
    
    h1 span.is-size-5 {
         font-size: 2.5rem !important;
    }

    div.text {
        div, p {
            font-size: 16px !important;
    
            &.larger {
                font-size: 22px !important;
            }
    
            strong {
                font-size: 22px !important;
            }
        }
    }
}

.StripeElement--invalid {
    border-color: #D0252B !important;
}

@media only screen and (max-width:360px) {         
    #card-element {
        padding: 10px 5px !important;
        border-width: 2px !important;
    }
}

main[role=main].checkout {
    section.intro {

        h1.is-size-2 {
            font-size: 4.5rem !important;
        }
        
        h2 {
            margin-bottom: 0 !important;
        }

        .summary {
            display: flex;
            width: 100%;
            margin-top: 2rem;
            align-items:flex-end;
            max-width: 740px;

            @include phablet-down {
                flex-direction: column;
                text-align: left;
            }

            .desc {
                flex-grow: 1;

                p.is-size-6 {
                    font-size: 18px !important;
                    line-height: 1.2em !important;
                }

                strong {
                    font-size: 24px !important;
                    display: block;
                    margin-bottom: 1rem;
                }

                p {
                    @include phablet-down {
                        text-align: left;
                    }
                }

            }

            .price {
                text-align: right;
                display: flex;
                flex-direction: column;
                align-items:flex-end;

                @include phablet-down {
                    align-items:flex-start;
                    margin-top: 2rem;
                }

                @include phablet-down {
                    text-align: left;
                }

                p {
                    display: flex;
                    font-size: 18px !important;
                    line-height: 1.2em !important;
                }
                
                em, strong {
                    padding: 0.25rem;
                    max-width: 6rem;
                    min-width: 6rem;                    
                    text-align: left;

                    @include phablet-down {
                        min-width: 5rem;
                        text-align: left;
                    }                
                }

                span {
                    text-align: right;
                    margin-right: 0.5rem;
                    padding: 0.25rem 0.5rem;

                    @include phablet-down {
                        text-align: left;
                    }

                    &.total {
                        background:var(--blue);
                        color: #fff;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

section.tickets {
    text-align: center;
    padding: 8rem 0 15rem;
    display: flex;
    align-items: center;

    h2 {
        // @extend %h2;
        color: var(--blue);
        margin: 0 0 -1rem 3rem;
        line-height: 1em;
        text-align: left;
        font-size: 2.5rem;
        line-height: 1em;
        letter-spacing: -0.05em;       

        @include legacy-down {
            margin-left: 0;
        }

        @include mobile {
            position: relative;
            z-index: 10;
            font-size: 1.8rem;
        }

        @include tiny {
            margin-bottom: 2rem;
            text-align: center;

        }
    }

    p.footer {
        margin: 3rem auto;
        text-align: center;
        font-size: 1.2rem;
        color: #555;
        line-height: 1.5em;
        
        @include mobile {
            font-size: 1rem;
        }

        @include phablet-down {
            max-width: 360px;
        }

        a {
            color: var(--blue);
            font-weight: 600;
        }
    }

    .wrapper {
        display: flex;
        padding: 0 4rem;
        position: relative;

        @include widescreen {
            padding: 0 8rem;
        }

        @include legacy-down {
            padding: 0 0rem;
        }

        @include tablet-down {
            flex-direction: column;
        }

        &::before {
            z-index: 0;
            content: "";
            display: block;
            position: absolute;
            bottom: -8px;
            left: calc( 8rem - 8px );
            height: 100px;
            width: 100px;
            background: var(--yellow);

            @include legacy-down {
                left: -8px;
            }
        }

        &::after {
            z-index: 0;
            content: "";
            display: block;
            position: absolute;
            top: -8px;
            right: calc( 8rem - 8px );
            height: 100px;
            width: 100px;
            background: var(--purple);

            @include legacy-down {
                right: -8px;
            }
        }

        .option {
            background: var(--blue);
            padding: 4rem 3rem 4rem;
            position: relative;
            z-index: 1;

            &.regular {
                min-width: 65%;
                margin-right: 3rem;
                position: relative;
                z-index: 1;

                @include tablet-down {
                    margin-right: 0rem;
                    margin-bottom: 3rem;
                }


                .details {
                    display: flex;

                    &.bottom {
                        padding-top: 2rem;
                        align-items: center;

                        p {
                            color: #fff;
                            text-transform: uppercase;
                            font-weight: 600;
                        }

                        .prices {
                            @include mobile {
                                margin-bottom: 0rem;
                            }                            
                        }
                    }

                    @include mobile {
                        flex-direction: column;

                    }

                    .prices {
                        min-width: 35%;
                        max-width: 35%;

                        @include mobile {
                            min-width: 100%;
                            max-width: 100%;
                            margin-bottom: 6rem;
                        }

                        .priceWrapper {
                            @include mobile {
                                display: flex;
                                justify-content: center;
                            }

                            @include tiny {
                                display: block;
                            }

                            .price {
                                margin-bottom: 3rem;

                                @include mobile {
                                    margin: 0 3rem 3rem;
                                }

                            }
                        }

                        .buttonWrapper {
                            a {
                                background: var(--yellow);
                                color: var(--blue);
                                margin-top: 0;

                                @include mobile {
                                    width: calc( 100% - 4rem );
                                }
                            }
                        }
                    }
                }

                .features {
                    text-align: left;
                    padding: 0.5rem 0 0 3rem;

                    @include mobile {
                        padding-left: 0;
                    }
    
                    strong {
                        font-size: 1.8rem;
                        font-weight: 700;
                        color: #A9E5F3;
                        display: block;
                        text-transform: uppercase;

                        @include mobile {
                            padding-left: 3.5rem;
                        }
                    }
    
                    ul {

                        li {
                            margin-bottom: 1.5rem;
                            font-size: 1.3rem;
                            line-height: 1.5em;
                            color: #fff;

                            @include phablet {
                                font-size: 1.2rem;
                            }

                            @include mobile {
                                font-size: 1rem;
                            }

                            a {
                                color: #fff;

                                &:hover, &:focus {
                                    color: var(--yellow);
                                }
                            }
                        }
                        
                    }
                }
            }


            .prices {
                display: flex;
                flex-direction: column;
                text-align: center;

                strong {
                    font-size: 2.5rem;
                    font-weight: 700;
                    color: #fff;
                    display: block;
                    margin-bottom: 1rem;
                    margin-top: 5rem;

                    &:first-of-type {
                        margin-top: 0;
                    }
                }

                span.price {
                    display: block;
                    text-align: center;
                    color: #A9E5F3;
                    font-size: 1.8rem;
                    font-weight: 700;
                }

                p {
                    font-size: 1.2rem;
                    line-height: 1.8em;
                    padding-bottom: 2rem;
                    color: #fff;

                    @include phablet {
                        font-size: 1.2rem;
                    }

                    @include mobile {
                        font-size: 1rem;
                    }
                }

                a {
                    color: #29ABE2;
                    font-weight: 600;
                    font-size: 1.4rem;
                    margin-top: 1rem;
                    text-decoration: none;
                }
            }

            &.supporter {

                span.price {
                    color: #29ABE2;
                }

                .buttonWrapper {
                    @include legacy-up {
                        position: absolute;
                        bottom: 4rem;
                        text-align: center;
                        width: calc( 100% - 6rem );
                    }


                    .button {
                        display: block;
                        background: var(--purple);
                        margin: 0 auto;
                    }
                }

                p {
                    font-size: 14px;
                    line-height: 1.3em;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }

                p + p {
                    padding-bottom: 2rem;
                }

            }

            .buttonWrapper {
                .button {
                    padding: 1.5rem 2rem;
                    font-size: 1.8rem;

                    @include mobile {
                        font-size: 2.2rem;
                    }
                }
            }
        }
    }
}


main[role=main].checkout {

    .eventNote {
        font-size: 18px !important;;
        line-height: 1em;
        font-family: var(--headline);
        font-weight: 900;
        color: var(--red) !important;;
        padding: 20px 0;
    }

    h2 {
        color: #162A50;
        font-weight: 900;
        font-size: 2.5rem;
        line-height: 1em;
        max-width: 960px;
        font-family: var(--headline);
        text-transform: uppercase;
        margin : 0 0 1.5rem;

        @include mobile {
            font-size: 1.8rem;
            text-align: center;
        }
    }
    
    p {
        color: #162A50;
        font-size: 1.2rem;
        line-height: 1.4em;
        font-family: var(--text);
        margin: 0;

        @include widescreen {
            font-size: 1.2rem;
        }

        &.legal {
            font-size: 1rem;
        }
    }

    

    .ticketTypes {
        display: flex;
        flex-direction: column;
        max-width: 1200px;
        gap:3rem;
        padding: 3rem 0;

        .type {
            min-width: 100%;
            margin-bottom: 1rem;
            align-items: start;
            display: flex;

            @include phablet-down {
                flex-direction: column;
            }

            &:not(:last-of-type) {
                border-bottom: 1px solid #ccc;
                padding-bottom: 5rem;

                @include phablet-down {
                    padding-bottom: 10rem;
                }
            }

            &.alt {
                border-top: 1px solid #777;
                padding-top: 1rem;

                @include phablet-down {
                    padding-top: 10rem;
                }

                .text {
                    h2 {
                        color: #888;
                    }
                }

                .price {
                    color: #888;
                }
            }

            .text {
                flex-grow: 1;

                h2 {
                    margin-bottom: 0.5rem;
                }
                p {
                    margin-top: 0;

                    a {
                        color: #29ABE2;
                        font-weight: bold;

                        &:hover, &:focus {
                            background: #29ABE2;
                            color: #fff;
                            text-decoration: none;
                        }
                    }
                }

                ul {
                    li {
                        font-size: 18px;
                    }
                }
            }

            .price {
                flex-grow: 1;
                font-size: 1.8rem;
                line-height: 1em;
                font-family: var(--headline);
                font-weight: 900;
                color: #29ABE2;
                margin: 0 3rem 0 3rem;
                text-align: right;
                padding-top: 0.5rem;
            }

            .link {
                justify-self: flex-end;
                padding-top: 0.15rem;
                min-width: 12rem;
                text-align: center;

                .button {
                    white-space: nowrap;
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
            }
        }
    }

    section.intro {
        padding-bottom: 50px;

        .button.small {
            padding: 1rem 3rem;
            font-size: 1.4rem;
            margin-right: 2rem;
        }

        div.totals { 
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 2rem 0;
            font-size: 18px !important;

            div {
                display: flex;
                flex-direction: row;
                text-align: left;
                line-height: 1.5em;

                span {
                    margin-left: 1rem;
                    text-align: right;
                }

                em {
                    min-width: 5rem;
                    max-width: 5rem;
                }
            }
        }
    }

    section.order {
        background-color: #f5f5f5;
        padding: 50px 0 150px 0;

        p {
            a:not(.button) {
                color: var(--lightBlue);
            }
        }

        h2.label {
            font-size: 1.8rem;

            @include phablet-down {
                font-size: 1.2rem;
            }       
        }

        div.grid,
        div.names {
            max-width: 540px;
            display: flex;
            justify-content: space-between;
            
            div.item,
            div.field {
                max-width: 48%;
                min-width: 48%;
            }

            &.hidden {
                display: none;
            }
        }

        div.item,
        div.field {
            max-width: 540px;
            display: flex;
            flex-direction: column;
            padding-bottom: 2rem;

            input, select {
                padding: 1rem;
            }
        }

        .buttons {
            padding-top: 2rem;
        }

        label {
            display: block;
            font-size: 16px;
            font-weight: 900;
            line-height: 1.25em;
            font-family: var(--headline);
            text-transform: uppercase;
            margin-bottom: 0.5rem;

            @include phablet-down {
                font-size: 1.2rem;
            }
        }

        p.small {
            padding-top: 1rem;
            font-size: 1rem;
        }

        #payment-form {
            max-width: 740px;
        }

        .gateway-payment-form {
            padding-bottom: 2rem;

            fieldset {
                border: none;
                padding: 0;
                margin-bottom: 2rem;

                &.card-data {
                    div { 
                        display: flex;

                        input {
                            padding: 1rem;

                            &.card-number {
                                min-width: 27rem;
                            }

                            &.card-expiry {
                                margin-left: 2rem;
                                max-width: 8rem;
                            }

                            &.card-cvc {
                                margin-left: 2rem;
                                max-width: 8rem;
                            }
                        }
                    }
                }
            }

            legend {
                display: block;
                font-size: 16px;
                font-weight: 900;
                line-height: 1.25em;
                font-family: var(--headline);
                text-transform: uppercase;
                margin-bottom: 0.5rem;
            }
        }
    }

    section.faqQuestions {
        background: #D5F9DE;
        padding: 8rem 0;
        position: relative;
        z-index: 2;

        h3 {
            color: #162A50;
            font-weight: 900;
            font-size: 3.5rem;
            line-height: 1em;
            max-width: 960px;
            font-family: var(--headline);
            text-transform: uppercase;
            margin : 0 0 2rem;
    
            @include mobile {
                font-size: 4rem;
            }
        }

        .question {
            margin-bottom: 8rem;
            max-width: 780px;


            &.policy {
                h3 {
                    font-size: 2.8rem;
                }

                p {
                    font-size: 16px;
                }
                    
            }

            p {
                margin-bottom: 18px;
                &:last-of-type {
                    margin-bottom: 0rem;
                }
            }

            a {
                color: var(--blue);

                &:hover, &:focus {
                    background: var(--blue);
                    color: #fff;
                    text-decoration: none;
                }
            }

        }
        
        p {
            color: #162A50;
            font-size: 2.2rem;
            line-height: 1.6em;
            font-family: var(--text);
            margin: 0;
    
            @include widescreen {
                font-size: 2.5rem;
            }
        }
    }

    .qtyForm {
        padding-top: 3rem;
        display: flex;
        flex-direction: column;

        .wrapper {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        sub {
            font-size: 0.75rem;
            padding-left: 0.5rem;
            transform: translateY(-0.6rem);
            display: inline-block;
        }

        strong {
            padding: 0 2rem;
        }

        div.quantity {
            position: relative;
            label {
                position: absolute;
                top: -2rem;

                @include phablet-down {
                    top: -3rem;
                }
            }
        }

        div.total {
            @include phablet-down {
                padding-top: 2rem;
                padding-left: 5rem;
            }

            // min-width: 200px;
        }

        label {
            font-size: 16px;
            font-weight: 900;
            line-height: 1.25em;
            font-family: var(--headline);
            text-transform: uppercase;
            display: block;
            margin-bottom: 10px;

            @include phablet-down {
                font-size: 14px;
            }
        }

        input {
            padding: 1.5rem 0rem;
            width: 100px;
            text-align: center;
            font-weight: bold;
            color: var(--dark);
            border: none;
            background: var(--yellow);

            font-weight: 900;
            font-size: 2rem;
            font-family: var(--headline);
            text-transform: uppercase;
        }

        div {
            margin-right: 1rem;
            font-weight: 900;
            font-size: 2rem;
            font-family: var(--headline);
            text-transform: uppercase;
        }
    }
}

