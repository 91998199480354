.c-block.block-apply {
    max-width: 600px;
}

.c-block form .fields {

    div.field {
        padding-bottom: 30px;

        label { 
            font-size: 22px;
            font-weight: bold;
            display: block;
            margin-bottom: 5px;
        }

        textarea {
            max-width: calc( 100% - 22px );
            min-width: calc( 100% - 22px );
            min-height: 200px;
            padding: 10px;
            font-size: 18px;
            background: #e8f0fe;
            border: 1px solid var(--blue);
            font-family: var(--text);

            &:focus {
                box-shadow: 0 0px 8px var(--brightBlue);
            }
        }

        input[type=text],
        input[type=email],
        input[type=password] {
            padding: 10px;
            font-size: 18px;
            width: calc( 100% - 22px );
            background: #e8f0fe;
            border: 1px solid var(--blue);
            font-family: var(--text);

            &:focus {
                box-shadow: 0 0px 8px var(--brightBlue);
            }
        }
    }
}

.block-callout form {

    padding-top: 40px;

    div.submit {
        padding-top: 10px;
    }

    div.threecols,
    div.social {
        display: flex;
        justify-content: space-between;
        
        @include phablet-down {
            flex-direction: column;
        }

        div.field {
            width: 30%;

            @include phablet-down {
                min-width: 100%;
            }
        }
    }

    div.twocols {
        display: flex;
        justify-content: space-between;

        @include phablet-down {
            flex-direction: column;
        }

        div.field {
            width: 47%;

            @include phablet-down {
                min-width: 100%;
            }
        }
    }

    div.social {
        display: flex;
        justify-content: space-between;

        div.field {
            width: 30%;
        }
    }


    .note {
        display: block;
        margin-top: 5px;
        color: #fff;
    }

    .fields {
        label { 
            color: #fff;
        }
    }
}

div.membershipTypes {
    p {
        margin-bottom: 5px;
    }

    .type {
        font-size: 22px;
        margin-bottom: 10px;
    }

    div.radio {
        
        margin : 0 0 0 24px;

        input[type="radio"] {
            opacity: 0;
            
            + label {
                position: relative;
                display: block;
                cursor: pointer;
                padding-left: 10px;

                strong {
                    display: block;
                }

                span {
                    font-size: 18px;
                }
              
                &::before {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    top: 3px;
                    left: -24px;
                    border-radius: 50%;
                    border: 1px solid #6F686A;
                    width: 18px;
                    height: 18px;
                }
              
                &::after {
                    content: '';
                    position: absolute;
                    display: inline-block;
                    left: -20px;
                    top: 7px;
                    border-radius: 50%;
                    width: 12px;
                    height: 12px;
                }
            }
            
            &:checked {
                + label::after {
                    background: var(--brightBlue);
                }
            }
            
            &:focus {
                + label::before {
                    box-shadow: 0 0px 8px var(--brightBlue);
                }
            }
        }
    }

    div.dropdown {
        margin-left: 34px;
        padding-top: 13px;
        padding-bottom: 20px !important;
    }
}


div.submit {
    padding-top: 50px;
}

/* class applies to select element itself, not a wrapper element */
.select-css {
	display: block;
	font-size: 16px;
	font-weight: normal;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
	max-width: 100%; /* useful when width is set to anything other than 100% */
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	border-radius: .5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	/* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
		for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
		
	*/
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	/* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
	background-position: right .7em top 50%, 0 0;
	/* icon size, then gradient */
	background-size: .65em auto, 100%;
}
/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
	display: none;
}
/* Hover style */
.select-css:hover {
	border-color: #888;
}
/* Focus style */
.select-css:focus {
	border-color: #aaa;
	/* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
	// box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
	// box-shadow: 0 0 0 3px -moz-mac-focusring;
	// color: #222; 
	// outline: none;
}

/* Set options to normal weight */
.select-css option {
    font-weight:normal;
    border: 4px solid red;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
	background-position: left .7em top 50%, 0 0;
	padding: .6em .8em .5em 1.4em;
}

/* Disabled styles */
.select-css:disabled, .select-css[aria-disabled=true] {
	color: graytext;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
}

.select-css:disabled:hover, .select-css[aria-disabled=true] {
	border-color: #aaa;
}


.grecaptcha-badge {
    display: none !important;
}