div.date-input {
    input {
        box-shadow: inset 0 0.0625em 0.125em rgba(10,10,10,.05);
        max-width: 100%;
        width: 100%;
        background-color: #fff;
        border-color: #dbdbdb;
        border-radius: 4px;
        color: #363636;
        -moz-appearance: none;
        -webkit-appearance: none;
        align-items: center;
        border: 1px solid transparent;
        border-radius: 4px;
        box-shadow: none;
        display: inline-flex;
        font-size: 1rem;
        height: 2.5em;
        justify-content: flex-start;
        line-height: 1.5;
        padding-bottom: calc(.5em - 1px);
        padding-left: calc(.75em - 1px);
        padding-right: calc(.75em - 1px);
        padding-top: calc(.5em - 1px);
        position: relative;
        vertical-align: top;

        &:focus {
            outline: none;
            border-color: #3273dc;
            box-shadow: 0 0 0 0.125em rgba(50,115,220,.25);
        }
    }
}