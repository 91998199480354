h1.page-h1 {
    @include mobile {
        font-size: 30px;
    }   
}

.content.introBlock {

    p.wide {
        @include desktop-up {
            margin-right: -150px;
        }
    }

    .laptop {
        @include desktop-up {
            transform:translateX(100px);
        }
    }
}

.content {
    .feature {
        @include tablet-up {
            max-width: 31%;
            width: 31%;
        }

        @include phablet {
            max-width: 100%;
            width: 100%;
        }        
    }
}



header.base-header { 
    img {
        @include mobile {
            max-height: 70px;
        }
    }
}


section.repeater {
    ul {
        list-style: initial;
        margin-left: 1.5rem;

        li {
            margin-bottom: 0.5em;

            p {
                margin: 0;
            }
        }
    }
}