a.enlarge,
a.expandLink {
  position: inherit !important;
    &:after {
        content: '';
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        position: absolute;
        display: block;
        z-index: 100;
    }
}

.video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    margin: 40px 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@keyframes slideUp {
    from { transform: translateY(100px); opacity: 0; }
    to {transform: translateY(0px); opacity: 1;}
}

@keyframes revealDown {
    from { max-height: 0; opacity: 0; }
    to { max-height: 2000px; opacity: 1; }
}

@function color($color-name) {
    @return var(--#{$color-name});
}

@mixin reset-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
  
@mixin horizontal-list {
    @include reset-list;
  
    li {
        display: inline-block;
        margin: 0;
        padding: 0;
    }
}

@mixin no-mobile-brs {
  @include phablet-down {
    br  {
      content: ' '
    }

    br:after {
        content: ' '
    }
  }
}

nav ul, nav ol {
    @include horizontal-list;
}

@mixin full-width-mobile {
  @include mobile {
    min-width: 100%;
    max-width: 100%;
  }
}

@mixin full-width-phablet-down {
  @include phablet-down {
    min-width: 100%;
    max-width: 100%;
  }
}

@mixin full-width-tablet {
  @include tablet {
    min-width: 100%;
    max-width: 100%;
  }
}

@mixin full-width-tablet-down {
  @include tablet-down {
    min-width: 100%;
    max-width: 100%;
  }
}

.scrollTop {
    scroll-margin-top: 10rem;
}

.relative {
    position: relative;
}
